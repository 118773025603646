<template>
    <div v-if="stripe.has_account" class="w-1/2">
        <div class="card-table">
            <div class="flex border-b py-4 py-2">
                <div class="w-1/3">
                    Business Name
                </div>
                <div class="w-2/3">
                    {{ stripe.account_information.business_name === null ? stripe.account_information.display_name : stripe.account_information.business_name }}
                </div>
            </div>
            <div class="flex border-b py-4 py-2">
                <div class="w-1/3">
                    Contact Email
                </div>
                <div class="w-2/3">
                    {{ stripe.account_information.email }}
                </div>
            </div>
            <div class="flex border-b py-4 py-2">
                <div class="w-1/3">
                    Default Currency
                </div>
                <div class="w-2/3">
                    {{ stripe.account_information.default_currency.toUpperCase() }}
                </div>
            </div>
        </div>
        <div class="mt-4">
            <button type="button" class="button text-white bg-purple-900 rounded py-2 px-4" v-on:click="stripe.has_account = false">
                Disconnect
            </button>
        </div>
    </div>
    <div v-else>
        <p class="mb-4">Authorizing Stripe for use with EventForge is easy. If you
            already have a Stripe account, click "Connect with Stripe" below.</p>
        <p class="mb-8"><span class="text-bold">Don't have a Stripe account?</span><br>If
            you do not have a Stripe account, you will be able to create an account in the
            same "Connect with Stripe" process!</p>
        <p>
            <a :href="`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${connect.client_id}&scope=read_write&redirect_uri=${connect.url}&state=${connect.org_id}`"
               class="inline-block bg-purple-900 text-base text-white w-auto py-2 px-4 shadow rounded">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                 class="icon-security h-6 w-6 inline-block" fill="#fff">
                <path class="primary"
                      d="M4 4l8-2 8 2v9.06a8 8 0 0 1-4.42 7.15L12 22l-3.58-1.79A8 8 0 0 1 4 13.06V4z"/>
                <path class="secondary"
                      d="M6 12V5.56l6-1.5V12H6zm6 7.76V12h6v1.06a6 6 0 0 1-3.32 5.36L12 19.76z"/>
            </svg>
            Connect with Stripe
            </a>
        </p>
    </div>
</template>
<script>
    export default {
        props: ['stripe', 'connect']
    }
</script>
