<template>
    <div class="md:flex md:flex-wrap">
        <campaign-card v-for="campaign in campaigns" :campaign="campaign" :key="campaign.id" :organization="organization"></campaign-card>

        <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 rounded overflow-hidden shadow-lg mb-4 sm:m-4 bg-gray-100 text-center pt-40 p-4 align-bottom">
            <span class="block text-2xl text-purple-800 text-md mb-4">Create New Campaign</span>
            <a href="/organization/campaigns/create" class="bg-teal-200 rounded-full h-20 w-20
            flex items-center justify-center text-xl text-white m-auto">+</a>
        </div>
    </div>
</template>
<script>
    import CampaignCard from "./CampaignCard";
    export default {
        props: ['campaigns', 'organization'],
        components: {
            CampaignCard
        }
    }
</script>
