export default {
    props: {
        value: {}
    },
    data: function(){
        return {
            lazyValue: this.value
        };
    },
    computed: {
        internalValue: {
            get: function(){
                return this.lazyValue;
            },
            set: function(value){
                this.lazyValue = value;
                this.$emit('input', value);
            }
        }
    },
    watch: {
        value: function(newValue){
            this.lazyValue = newValue;
        }
    }
};
