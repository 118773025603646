<template>
    <div :class="{ open: open }" @click.self="open = false" class="date-range-select">
        <div class="flex">
            <div @click="toggle"
                 class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" :class="{ 'border-red-400' : error }">{{ valueSelectionText }}</div>
            <button type="button" @click="toggle"
                    class="bg-purple-900 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded rounded-l-none focus:outline-none focus:shadow-outline">Open</button>
        </div>
        <div :class="{ 'date-range-popup--time': timePicker, 'date-range-popup--non-range': !rangePicker }"
             class="date-range-popup">
            <div class="date-range-popup_main">
                <div class="item">
                    <div class="date-range-header">
                        <button @click="backwardMonth" class="nav-button">
                            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"/>
                            </svg>
                        </button>
                        <div class="month-title">{{ leftMonth }}</div>
                        <div class="month-title" v-if="rangePicker">{{ rightMonth }}</div>
                        <button @click="forwardMonth" class="nav-button">
                            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="months">
                        <div class="month">
                            <div class="title-row">
                                <div class="title">Sun</div>
                                <div class="title">Mon</div>
                                <div class="title">Tue</div>
                                <div class="title">Wed</div>
                                <div class="title">Thu</div>
                                <div class="title">Fri</div>
                                <div class="title">Sat</div>
                            </div>
                            <div class="cell-row" v-for="week in daysInLeftMonth">
                                <div :class="{ empty: day.length === 0, selected: isSelected(day), between: isBetween(day) || isInHover(day) }"
                                     @click="selectDay(day)"
                                     @mouseover="setHoverDate(day)" class="cell" v-for="day in week"><span>{{ toDayNumber(day) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="month" v-if="rangePicker">
                            <div class="title-row">
                                <div class="title">Sun</div>
                                <div class="title">Mon</div>
                                <div class="title">Tue</div>
                                <div class="title">Wed</div>
                                <div class="title">Thu</div>
                                <div class="title">Fri</div>
                                <div class="title">Sat</div>
                            </div>
                            <div class="cell-row" v-for="week in daysInRightMonth">
                                <div :class="{ empty: day.length === 0, selected: isSelected(day), between: isBetween(day) || isInHover(day) }"
                                     @click="selectDay(day)"
                                     @mouseover="setHoverDate(day)" class="cell" v-for="day in week"><span>{{ toDayNumber(day) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item time-selector">
                    <div class="time-group">
                        <div class="time-group-title" v-if="rangePicker">From</div>
                        <div class="time-group-value">
                            <template v-if="selection.begin !== null">
                                {{ selection.begin | dateFormatF(timePicker ? 'MMMM Do YYYY h:mm a':'MMMM Do YYYY') }}
                            </template>
                            <template v-else>
                                Select a Date
                            </template>
                        </div>
                    </div>
                    <div class="time-group" v-if="rangePicker">
                        <div class="time-group-title">To</div>
                        <div class="time-group-value">
                            <template v-if="selection.end !== null">
                                {{ selection.end | dateFormatF(timePicker ? 'MMMM Do YYYY h:mm a':'MMMM Do YYYY') }}
                            </template>
                            <template v-else>
                                Select a Date
                            </template>
                        </div>
                    </div>
                    <div class="text-right">
                        <button @click="open = false" class="bg-transparent py-1 px-2" type="button">Cancel</button>
                        <button @click="select" class="bg-purple-900 rounded py-1 px-2 text-white" tabindex="3" type="button">Select</button>
                    </div>
                </div>
            </div>
            <div class="time-select" v-if="timePicker">
                <div class="time-select-group">
                    <div class="form-group" v-if="selection.begin !== null">
                        <label>
                            <template v-if="rangePicker">
                                Start Time
                            </template>
                            <template v-else>
                                Time
                            </template>
                        </label>
                        <div class="input-group">
                            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" tabindex="1" type="text" v-model.lazy="start_time"/>
                            <button @click="flip('start_time_am')" class="addon button" type="button">{{ start_time_am
                                }}
                            </button>
                        </div>
                        <div class="help-text">
                            Enter the time of day
                            <template v-if="rangePicker"> for the start date</template>
                        </div>
                    </div>
                    <div class="form-group" v-if="selection.end !== null">
                        <label>End Time</label>
                        <div class="input-group">
                            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" tabindex="2" type="text" v-model.lazy="end_time"/>
                            <button @click="flip('end_time_am')" class="addon button" type="button">{{ end_time_am }}
                            </button>
                        </div>
                        <div class="help-text">Enter the time for the end date</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import InternalValueMixin from "../mixins/InternalValueMixin";

    export default {
        mixins: [InternalValueMixin],
        props: {
            timePicker: {
                default: function(){
                    return true;
                }
            },
            rangePicker: {
                default(){
                    return true;
                }
            },
            error: {
                default() {
                    return false;
                }
            }
        },
        data(){
            var begin = null;
            var end = null;
            if(this.value !== null){
                if(this.rangePicker){
                    var c = JSON.parse(JSON.stringify(this.value.begin));
                    var start_date = moment(c).toISOString();
                    var end_date = moment(c).add(1, 'month').toISOString();
                    begin = start_date;
                    if(this.value.end !== null){
                        end = end_date;
                    }
                } else {
                    var start_date = moment(this.value).toISOString();
                    begin = start_date;
                    var end_date = null;
                }
            } else {
                var start_date = moment().set({hour: 11, minute: 0}).toISOString();
                var end_date = moment().set({hour: 11, minute: 0}).add(1, 'month').toISOString();
            }
            return {
                start_date: start_date,
                end_date: end_date,
                hover_date: null,
                selection: {
                    begin: begin,
                    end: end
                },
                open: false
            };
        },
        watch: {
            'selection.end': function(v){
                if(v !== null){
                    this.hover_date = null;
                }
            },
            value: {
                deep: true,
                handler: function(v){
                    if(this.rangePicker){
                        this.selection = JSON.parse(JSON.stringify(v));
                    } else {
                        this.selection = {
                            begin: JSON.parse(JSON.stringify(v)),
                            end: null
                        };
                    }
                }
            },
            open: function (oldVal, newVal) {
                //console.log(newVal)
            }
        },
        methods: {
            toggle: function () {
                this.open = !this.open;
            },
            flip: function(v){
                this.$set(this, v, this[v] == 'am' ? 'pm' : 'am');
            },
            select: function(){
                let valueToEmit = this.rangePicker ? this.selection : this.selection.begin;
                this.$emit('input', valueToEmit);
                this.open = false;
            },
            setHoverDate: function(d){
                if(d.length === 0) return;
                if(this.selection.begin !== null && this.selection.end === null){
                    this.hover_date = d;
                }
            },
            isBetween(d){
                return moment(d).isBetween(this.selection.begin, this.selection.end, 'day');
            },
            isInHover(d){
                if(!this.rangePicker) return false;
                if(this.selection.begin !== null && this.hover_date !== null){
                    return moment(d).isBetween(this.selection.begin, this.hover_date, 'day');
                }
                return false;
            },
            isSelected(d){
                var m = moment(d);
                return Object.values(this.selection).filter(function(i){
                    return moment(i).isSame(m, 'day');
                }).length > 0;
            },
            forwardMonth(){
                let c = JSON.parse(JSON.stringify(this.start_date));
                c = c === null ? new Date() : c;
                this.start_date = moment(c).add(1, 'month').toISOString();
                this.end_date = moment(c).add(2, 'months').toISOString();
            },
            backwardMonth(){
                let c =JSON.parse(JSON.stringify(this.start_date));
                c = c === null ? new Date() : c;
                this.start_date = moment(c).subtract(1, 'month').toISOString();
                this.end_date = c;
            },
            toDayNumber: function(d){
                if(d.length == 0) return '';
                return moment(d).format('D');
            },
            selectDay: function(d){
                if(d.length === 0) return;
                if(!this.rangePicker){
                    this.selection.begin = d;
                    return;
                }
                if(this.selection.begin === null && this.selection.end === null){
                    //initial state
                    //set begin to selection
                    this.selection.begin = d;
                    return;
                }
                if(this.selection.begin !== null && this.selection.end === null){
                    //begin is set and end is not
                    if(moment(this.selection.begin).isBefore(d)){
                        //current selection is before click value
                        this.selection.end = d;
                    } else {
                        if(moment(this.selection.begin).isSame(d, 'day')){
                            this.selection.end = d;
                        } else {
                            this.selection.begin = d;
                        }
                    }
                    return;
                }
                if(this.selection.begin !== null && this.selection.end !== null){
                    //both are set
                    var v = moment(d);
                    if(v.isBefore(this.selection.begin)){
                        this.selection.begin = d;
                    } else if(v.isAfter(this.selection.end)){
                        this.selection.end = d;
                    } else if(v.isAfter(this.selection.begin) && v.diff(this.selection.begin, 'day') == 1){
                        this.selection.begin = d;
                    } else if(v.isBefore(this.selection.end) && v.diff(this.selection.end, 'day') == 1){
                        this.selection.end = d;
                    } else {
                        this.selection.begin = d;
                        this.selection.end = null;
                    }

                }
            }
        },
        computed: {
            start_time_am: {
                get(){
                    return moment(this.selection.begin).format('a');
                },
                set(v){
                    var m = moment(this.selection.begin).format('M/D/YYYY h:ss a');
                    var pieces = m.split(' ');
                    pieces[2] = v;
                    this.selection.begin = moment(pieces.join(' ')).toISOString();
                }
            },
            end_time_am: {
                get(){
                    return moment(this.selection.end).format('a');
                },
                set(v){
                    var m = moment(this.selection.end).format('M/D/YYYY h:ss a');
                    var pieces = m.split(' ');
                    pieces[2] = v;
                    this.selection.end = moment(pieces.join(' ')).toISOString();
                }
            },
            start_time: {
                get(){
                    return moment(this.selection.begin).format('h:mm');
                },
                set(v){
                    var which = this.start_time_am;
                    var hours = parseInt(v.split(':')[0]);
                    var minutes = parseInt(v.split(':')[1]);
                    if(which === 'pm'){
                        hours += 12;
                    }
                    this.selection.begin = moment(JSON.parse(JSON.stringify(this.selection.begin))).set({
                        hour: hours,
                        minute: minutes
                    }).toISOString();
                }
            },
            end_time: {
                get(){
                    return moment(this.selection.end).format('h:mm');
                },
                set(v){
                    var which = this.end_time_am;
                    var hours = parseInt(v.split(':')[0]);
                    var minutes = parseInt(v.split(':')[1]);
                    if(which === 'pm'){
                        hours += 12;
                    }
                    this.selection.end = moment(JSON.parse(JSON.stringify(this.selection.end))).set({
                        hour: hours,
                        minute: minutes
                    }).toISOString();
                }
            },
            valueSelectionText: function(){
                if(this.internalValue !== null){
                    if(!this.rangePicker){
                        return moment(this.internalValue).format(this.timePicker ? 'MMM Do YYYY h:mm a' : 'MMM Do YYYY');
                    }
                    var str = [];
                    if(this.internalValue.begin !== null){
                        str.push(moment(this.internalValue.begin).format(this.timePicker ? 'MMM Do YYYY h:mm a' : 'MMM Do YYYY'));
                    }
                    if(this.internalValue.end !== null){
                        str.push(moment(this.internalValue.end).format(this.timePicker ? 'MMM Do YYYY h:mm a' : 'MMM Do YYYY'));
                    }
                    return str.join(' through ');
                } else {
                    return 'No selection yet';
                }
            },
            currentSelectionText: function(){
                var str = [];
                if(this.selection.begin !== null){
                    str.push(moment(this.selection.begin).format(this.timePicker ? 'MMM Do YYYY h:mm a' : 'MMM Do YYYY'));
                }
                if(this.selection.end !== null){
                    str.push(moment(this.selection.end).format(this.timePicker ? 'MMM Do YYYY h:mm a' : 'MMM Do YYYY'));
                }
                if(str.length == 0){
                    return 'No selection yet';
                } else {
                    return str.join(' through ');
                }
            },
            leftMonth: function(){
                return moment(this.start_date).format('MMMM YYYY');
            },
            rightMonth: function(){
                return moment(this.end_date).format('MMMM YYYY');
            },
            daysInLeftMonth: function(){
                var days = moment(this.start_date).daysInMonth();
                var beginOfMonth = moment(this.start_date).startOf('month').hours(11).minutes(0);
                var items = [];
                for(var i = 0; i < this.numToSkipLeft; i++){
                    items.push('');
                }
                for(var i = 0; i < days; i++){
                    items.push(beginOfMonth.clone().add(i, 'days').toISOString());
                }
                var weeks = [];
                for(var i = 0; i < Math.ceil((days + this.numToSkipLeft) / 7); i++){
                    weeks.push(items.slice(i * 7, i * 7 + 7));
                }
                return weeks;
            },
            numToSkipLeft: function(){
                return parseInt(moment(this.start_date).startOf('month').format('d'));
            },
            daysInRightMonth: function(){
                var days = moment(this.end_date).daysInMonth();
                var beginOfMonth = moment(this.end_date).startOf('month').hours(11).minutes(0);
                var items = [];
                for(var i = 0; i < this.numToSkipRight; i++){
                    items.push('');
                }
                for(var i = 0; i < days; i++){
                    items.push(beginOfMonth.clone().add(i, 'days').toISOString());
                }
                var weeks = [];
                for(var i = 0; i < Math.ceil((days + this.numToSkipRight) / 7); i++){
                    weeks.push(items.slice(i * 7, i * 7 + 7));
                }
                return weeks;
            },
            numToSkipRight: function(){
                return parseInt(moment(this.end_date).startOf('month').format('d'));
            }
        }
    }
</script>
