<template>
    <transition name="fade">
        <div class="modal" v-if="show">
            <div class="modal__backdrop" @click="closeModal()"/>
            <div class="modal__dialog" :class="[bg, top, width]">
                <div class="flex items-start justify-between p-4">
                    <slot name="header"/>
                    <button v-if="closeable" type="button"
                            class="modal__close bg-black text-white absolute top-0 right-0 p-3 text-2xl -mt-4 -mr-4"
                            @click="closeModal()">
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                            <path
                                fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                            ></path>
                        </svg>
                    </button>
                </div>

                <div class="modal__body">
                    <slot name="body"/>
                </div>

                <slot name="footer"/>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Modal",
    props: {
        closeable: {
            type: Boolean,
            default: true
        },
        classList: {
            type: Object,
            default() {
                return {
                    bg: 'bg-white',
                    width: 'w-2/5',
                    top: 'mt-24'
                }
            }
        }
    },
    data() {
        return {
            bg: this.classList.bg ? this.classList.bg : 'bg-white',
            width: this.classList.width ? this.classList.width : 'w-2/5',
            top: this.classList.top ? this.classList.top : 'mt-24',
            show: false,

        };
    },
    methods: {
        closeModal() {
            if (!this.closeable) return;

            this.show = false;
            document.querySelector("body").classList.remove("overflow-hidden");
            this.$emit('closed');
        },
        openModal() {
            this.show = true;
            document.querySelector("body").classList.add("overflow-hidden");
            this.$emit('open');
        }
    }
};
</script>


<style lang="scss" scoped>
.modal {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    &__backdrop {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    &__dialog {
        //background-color: #ffffff;
        position: relative;
        //min-width: 650px;
        //max-height: 65vh;
        //overflow: scroll;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        z-index: 2;
        @media screen and (max-width: 992px) {
            min-width: 90%;
        }
    }
    &__header {
        padding: 20px 20px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__body {
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
