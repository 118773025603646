export default {
    methods: {
        formatCurrency: function(value) {
            if (typeof value !== "number") {
                return value;
            }
            var formatter = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2
            });

            return formatter.format(value);
        },
    }
}
