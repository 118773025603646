<template>
    <div class="h-3 relative max-w-xl rounded-full overflow-hidden">
        <div class="w-full h-full bg-gray-200 absolute"></div>
        <div class="h-full bg-blue-500 absolute" :style="{width:fill + '%'}"></div>
    </div>
</template>
<script>
    export default {
        props: ['goal', 'current'],

        computed: {
            fill() {
                return (this.current / this.goal) * 100;
            }
        }
    }
</script>
