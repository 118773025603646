<template>
    <transition name="fade">
        <span class="tooltip inline" :class="{active: active}">
            <slot name="trigger"/>
            <span class="tooltip-text">
                <slot name="tooltip"/>
            </span>
        </span>
    </transition>
</template>
<script>
    export default {
        name: "Tooltip",
        props: {
            active: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style lang="scss">
    .tooltip .tooltip-text {
        @apply hidden w-auto px-6 py-2 absolute shadow-lg rounded-sm border border-gray-500 z-50 text-sm bg-gray-400 -top-1/2 whitespace-no-wrap;
        transform:translate(-50%)
    }
    .tooltip:hover .tooltip-text,
    .tooltip.active .tooltip-text {
        @apply inline-block
    }
</style>
