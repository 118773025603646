<template>
    <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 rounded shadow-lg mb-4 sm:m-4">
        <div class="thumbnail">
            <div v-if="!campaign.hero" class="thumbnail bg-gray-300"></div>
            <img v-else class="w-full" :src="campaign.hero.path" :alt="campaign.title">
        </div>
        <div class="flex px-6 pt-4 pb-2">
            <div class="mb-2 flex-auto">
                <h3 class="font-bold text-xl">{{ campaign.name }}</h3>
                <span class="block text-sm text-green-400">Start Date: {{ campaign.start_date }}</span>
                <span class="block text-sm">End Date: {{ campaign.end_date }}</span>
            </div>
            <div class="flex-auto">
                <button v-if="campaign.active" class="bg-green-400 px-2 py-2 rounded text-xs uppercase float-right text-white inline-flex items-center">
                    <span>Active</span>
                    <svg class="fill-current w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V0L8.11 5.87 3 12h4v8L17 8h-4z"/></svg>
                </button>
                <button v-else class="bg-red-500 px-2 py-2 rounded text-xs uppercase float-right text-white">
                    <span>Innactive</span>
                    <svg class="fill-current w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V0L8.11 5.87 3 12h4v8L17 8h-4z"/></svg>
                </button>
            </div>
        </div>
        <div class="sm:flex px-6 py-2">
            <a :href="`campaigns/${campaign.id}/edit`" class="bg-purple-900 text-white rounded uppercase w-full sm:w-2/3 text-xs text-center mb-1 sm:mb-0 sm:mr-1 py-4 px-2 flex align-content-center items-center justify-center">
                <span>Manage Campaign</span>
                <svg class="fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M3.94 6.5L2.22 3.64l1.42-1.42L6.5 3.94c.52-.3 1.1-.54 1.7-.7L9 0h2l.8 3.24c.6.16 1.18.4 1.7.7l2.86-1.72 1.42 1.42-1.72 2.86c.3.52.54 1.1.7 1.7L20 9v2l-3.24.8c-.16.6-.4 1.18-.7 1.7l1.72 2.86-1.42 1.42-2.86-1.72c-.52.3-1.1.54-1.7.7L11 20H9l-.8-3.24c-.6-.16-1.18-.4-1.7-.7l-2.86 1.72-1.42-1.42 1.72-2.86c-.3-.52-.54-1.1-.7-1.7L0 11V9l3.24-.8c.16-.6.4-1.18.7-1.7zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="#fff"/>
                </svg>
            </a>
            <a :href="slug"
               class="bg-purple-900 text-white text-center rounded uppercase w-full sm:w-1/3 text-xs py-4 px-2 flex
               items-center justify-center" target="_blank">
                <span>View Page</span>
                <svg class="fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/></svg>
            </a>
        </div>
        <div v-if="campaign.page_short_url" class="sm:flex w-full px-6 py-2 relative">
            <input class="block appearance-none border border-r-0 rounded w-full py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" type="text" :value="campaign.page_short_url">
            <span class="block border border-l-0 py-2 px-3">
                <a href="#" @click.prevent="copy">
                    <tooltip ref="clipboardTip" :active="tooltipActive">
                        <template slot="trigger">
                            <svg class="block align-bottom text-gray-400" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="copy" fill="#cbd5e0">
                                        <path d="M0,8.00585866 C0,6.89805351 0.897060126,6 2.00585866,6 L11.9941413,6 C13.1019465,6 14,6.89706013 14,8.00585866 L14,17.9941413 C14,19.1019465 13.1029399,20 11.9941413,20 L2.00585866,20 C0.898053512,20 0,19.1029399 0,17.9941413 L0,8.00585866 L0,8.00585866 Z M6,8 L2,8 L2,18 L12,18 L12,14 L15,14 L15,12 L18,12 L18,2 L8,2 L8,5 L6,5 L6,8 L12,8 L12,14 L17.9941413,14 C19.1029399,14 20,13.1019465 20,11.9941413 L20,2.00585866 C20,0.897060126 19.1019465,0 17.9941413,0 L8.00585866,0 C6.89706013,0 6,0.898053512 6,2.00585866 L6,8 Z" id="Combined-Shape"></path>
                                    </g>
                                </g>
                            </svg>
                        </template>
                        <template slot="tooltip">
                            {{ clipboardText }}
                        </template>
                    </tooltip>
                </a>
            </span>
        </div>
        <div class="flex px-6 py-4 text-center">
            <div class="w-1/3 py-2 uppercase border-r">
                <span class="block">Raised</span>
                ${{ campaign.total_raised }}
            </div>
            <div class="w-1/3 py-2 border-r">
                <span class="block">Goal</span>
                ${{ (campaign.page) ? campaign.page.goal : 0 }}
            </div>
            <div class="w-1/3 py-2 ">
                <span class="block">Donors</span>
                {{ campaign.donor_count }}
            </div>
        </div>
    </div>
</template>
<script>
    import Modal from '../ui/Modal';
    import Tooltip from "../ui/Tooltip";

    export default {
        props:['campaign', 'organization'],

        components: {
            Modal,
            Tooltip
        },
        data() {
            return {
                slug: `/${this.organization.identifier}/${this.organization.slug}/campaign/${this.campaign.id}`,
                showModal: false,
                showShortUrl: false,
                tooltipActive: false,
            }
        },
        computed: {
            clipboardText: function () {
                if (this.tooltipActive) {
                    return 'Copied!'
                }

                return 'Copy Url'
            }
        },
        methods: {
            view() {
                if (this.organization.is_setup) {
                    window.location.href = `/${this.organization.identifier}/${this.organization.slug}/campaign/${this.campaign.id}`;
                    return
                }

                alert('Please complete your Stripe setup before proceeding.')
            },
            copy() {
                let text = this.campaign.page_short_url;
                let self = this;

                if (!navigator.clipboard) {
                    alert('Copy to clipboard is not available in your browser.')
                    return;
                }
                navigator.clipboard.writeText(text).then(function() {
                    console.log('Async: Copying to clipboard was successful!');
                    self.tooltipActive = true;
                    setTimeout(() => { self.tooltipActive = false}, 2000)
                }, function(err) {
                    console.error('Async: Could not copy text: ', err);
                });
            }
        }
    }
</script>

<style>
    .thumbnail {
        height: 270px;
        overflow:hidden;
    }
</style>
