<template>
    <div class="sm:flex sm:flex-wrap">
        <base-product-card v-for="product in products" :product="product" :key="product.id"></base-product-card>
    </div>
</template>
<script>
import BaseProductCard from "./BaseProductCard";

export default {
    props: ['products'],
    components: {
        BaseProductCard,
    }

}
</script>
