<template>
    <div class="w-full md:w-1/2 lg:w-1/4 rounded overflow-hidden shadow-lg mb-4 sm:m-4">
        <div class="thumbnail">
            <div v-if="!product.images" class="thumbnail bg-gray-300"></div>
            <img v-else class="w-full" :src="product.images[0].path" :alt="product.name">
        </div>
        <div class="px-6 py-4 flex">
            <div class="w-2/3">
                <div class="mb-2 flex-auto">
                    <h3 class="font-bold text-" v-html="product.name"></h3>
                </div>
                <div class="mb-2">
                    <div class="flex-auto">
                        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input type="checkbox" name="toggle" :id="'toggle-product-'+product.id"
                                   class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4
                                   appearance-none cursor-pointer focus:outline-none" v-model="enabled" @change="open"/>
                            <label :for="'toggle-product-'+product.id" class="toggle-label block overflow-hidden h-6
                            rounded-full bg-gray-300 cursor-pointer"></label>
                        </div>
                        <label class="text-gray-700 text-sm mb-1" v-html="toggleText"></label>
                    </div>
                </div>
            </div>
            <div class="w-1/3 text-right">
                <span class="block text-gray-500 font-bold text-lg">${{ product.markup_price | toCurrency }}</span>
                <a v-if="enabled" href="#" class="block text-small text-blue-400" @click.prevent="open">Edit</a>
            </div>
        </div>
        <modal ref="productModal" @closed="checkSelection">
                <template v-slot:header>
                    <h1 class="block mb-2 font-medium text-lg">{{ product.name }}</h1>
                </template>
                <template v-slot:body>
                    <div v-if="artwork.length > 1" class="p-4 mb-2">
                        <p class="mb-4">Choose the artwork for this product.</p>
                        <div class="flex space-x-2">
                            <div v-for="(image, index) in artwork" class="flex w-1/5 h-32 p-2 items-center border-4 mb-2
                                 cursor-pointer relative text-gray-400" :class="{'border-green-400 border-6' : selected === image.id}"
                                 @click="selectArtwork(index)">
                                <img class="w-32 block" :src="image.media.path" alt="print media">
                            </div>
                        </div>
                    </div>
                    <div class="p-4">
                        <p class="block mb-4 text-gray-900">Enable color choices and save your product.</p>
                        <form>
                            <div class="w-full py-2 px-1 border-b inline-flex" v-for="color in selectableColors">
                                <div class="w-3/5">
                                    <span class="h-6 w-6 inline-block align-middle mr-2 border border-gray-400" :style="{'background-color': color.value}"></span>
                                    <span class="text-sm align-middle">{{ color.description }}</span>
                                </div>
                                <div class="flex-1 ml-4 text-center">
                                    <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox" name="toggle" :id="'toggle-'+color.description"
                                               class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4
                                           appearance-none cursor-pointer focus:outline-none"
                                               :class="{'cursor-not-allowed':!enabled}"
                                               @change="enableColor" :value="color.description" :disabled="!enabled" :checked="colorEnabled(color.description)"/>
                                        <label :for="'toggle-'+color.description" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                                    </div>
                                    <label :id="'switch-'+color.description" class="text-xs text-gray-700">Enable Color</label>
                                </div>
                            </div>
                        </form>
                    </div>
                </template>
                <template slot="footer">
                    <div class="px-4 py-2">
                        <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs"
                                :class="{'cursor-not-allowed bg-gray-400':!enabled || saving}"
                                @click="saveProduct" v-html="button" :disabled="!enabled || saving"></button>
                    </div>
                </template>
        </modal>
    </div>
</template>
<script>
import Modal from '../ui/Modal';

export default {
    props:[
        'artwork',
        'product',
        'markup-type',
        'markup',
        'disable-selection'
    ],

    components: {
        Modal
    },
    data() {
        return {
            showModal: false,
            enabled: this.product.enabled,
            productArtwork: null,
            enabledColors: this.product.enabledColors,
            disabledColors: [],
            button: 'Update & Save Product',
            saving: false
        }
    },
    mounted() {
        this.artwork.forEach((x) => {
            if (this.product.enabledArtwork !== "" && x.printify_media_id === this.product.enabledArtwork) {
                this.productArtwork = x;
            }
            else if (x.default) {
                this.productArtwork = x;
            }
        })
    },
    computed: {
        selectableColors() {
            const colors = this.product.availableColors;
            const selected = this.product.colors.filter((x) => {
                return this.enabledColors.includes(x.description);

            });
            const available = colors.filter((x) => {
                return !this.enabledColors.includes(x.description);
            })

            available.push(...selected);
            return (available, colors);
        },
        selected() {
            if (this.productArtwork === null) return;
            return this.productArtwork.id;
        },
        toggleText() {
            return (this.enabled) ? 'Disable Product' : 'Enabled Product'
        },
    },
    methods: {
        mapOrder(array, order, key) {
            array.sort( function (a, b) {
                var A = a[key], B = b[key];

                if (order.indexOf(A) > order.indexOf(B)) {
                    return 1;
                } else {
                    return -1;
                }

            });

            return array;
        },
        open() {
            if (!this.enabled) return false;

            if (this.disableSelection) {
                alert('Product maximum reached.')
                return false;
            }

            this.$refs.productModal.openModal();
            this.$emit('product-added', this.product.id)
        },
        selectArtwork(index) {
            this.productArtwork = this.artwork[index]
        },
        colorEnabled(description) {
            return this.enabledColors.includes(description);
        },
        enableColor(e) {
            let input = e.target;

            if (input.checked) {
                console.log(input.value)
                this.disabledColors = this.disabledColors.filter(x => {
                    return x !== input.value;
                });
                this.enabledColors.push(input.value)
            } else {
                this.enabledColors = this.enabledColors.filter(x => {
                    return x !== input.value;
                });
                this.disabledColors.push(input.value)
            }
        },
        saveProduct() {
            if (this.enabledColors.length < 1) {
                alert('Please enable at least 1 color.');
                return;
            }

            this.button = 'Saving your product...'
            this.saving = true

            let request = {
                'printify_product_id': this.product.id,
                'name': this.product.name,
                'description': this.product.description,
                'price': this.product.variants[0].price,
                'enabledColors': this.enabledColors,
                'disabledColors': this.disabledColors,
                'available': this.enabled,
                'mediaId': this.productArtwork.printify_media_id
            }

            axios.post('/organization/products', request)
                .then(resp => {
                    this.$toasted.show(resp.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "top-center text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                    setTimeout(() => {
                        this.button = 'Update & Save Product';
                        this.saving = false;
                        this.$refs.productModal.closeModal();
                        window.location.reload();
                    }, 2000)
                }).catch(err => {
                this.$toasted.show(err, {
                    theme: "",
                    position: "top-center",
                    className: "bg-red text-white rounded-full",
                    type: "error",
                    duration: 5000,
                })
            })
        },
        checkSelection() {
            if (this.enabledColors.length < 1) {
                this.$toasted.show('You must select at least 1 color option to enable this product.', {
                    theme: "",
                    position: "top-center",
                    className: "bg-yellow text-white rounded-full",
                    type: "error",
                    duration: 5000,
                });
                this.enabled = false;
            }
        },
        updateAvailability() {
            let request = {
                'available': !this.enabled
            }

            axios.put(`/organization/products/${this.product.id}`, request)
                .then(resp => {
                    this.$toasted.show(resp.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "top-center text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                }).catch(err => {
                this.$toasted.show(err, {
                    theme: "",
                    position: "top-center",
                    className: "bg-red text-white rounded-full",
                    type: "error",
                    duration: 5000,
                })
            })
        }
    }
}
</script>

<style>
.thumbnail {
    height: 270px;
    overflow:hidden;
}
</style>
