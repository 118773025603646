<template>
    <div class="relative">
        <div class="flex mb-4">
            <a href="/organization/store" class="flex justify-cetner items-center
            bg-purple-900 text-white px-4 py-2 text-center">
                <span class="material-icons-outlined">
                chevron_left
                </span>
                Back to Store Setup</a>
            <p class="p-2 px-4 bg-purple-100 w-full lg:w-8/12 rounded ml-4 mr-4 text-lg">
                <span class="font-bold">Notice:</span> If the logo you've provided is dark-colored please be advised that it will not show well on dark-colored shirts.
                This should be taken into consideration when choosing colors as you add your products.</p>

        </div>

        <div class="sm:flex sm:flex-wrap">
            <base-product-card v-for="product in products"
                       :artwork="artwork"
                       :product="product"
                       :key="product.id"
                       :markup="markup"
                       :markup-type="markupType"
                       :disable-selection="disableSelection"
                       @product-added="added"></base-product-card>
        </div>

        <modal ref="modalName" :closeable="enabled">
            <template v-slot:header>
                <h1>Your Store</h1>
            </template>
            <template v-slot:body>
                <div class="p-4">
                    <form @submit.prevent="saveSettings">
                        <div class="mb-4">
                            <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                <input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none" v-model="enabled"/>
                                <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                            </div>
                            <label for="toggle" class="text-xs text-gray-700">Enable your store.</label>
                        </div>
                        <div v-if="enabled" class="border-t py-4">
                            <h3 class="block text-gray-700 text-sm font-bold mb-2">Set global product markup.</h3>
                            <p class="block text-gray-700 text-xs mb-4">
                                Defaults to percentage markup. You will be able to markup individual products during product selection if you choose to do so.</p>
                            <div class="mb-2 inline-flex">
                                <input type="text" class="shadow border rounded text-gray-700 leading-tight w-2/3 py-2 px-3 appearance-none focus:outline-none focus:shadow-outline" v-model="markup">
                                <span class="block ml-2 text-gray-700 py-1" v-if="markupType === 'percent'">%</span>
                                <span class="block ml-2 text-gray-700 py-1" v-if="markupType === 'dollar'">$</span>
                            </div>
                            <div class="mb-4">
                                <label class="inline-flex items-center mt-3">
                                    <input type="radio" class="border-gray-700 checked:bg-gray-900 checked:border-transparent" v-model="markupType" value="percent" :checked="markupType === 'percent'">
                                    <span class="ml-1 text-gray-700 text-xs">% Percent</span>
                                </label>
                                <label class="inline-flex items-center mt-3 ml-4">
                                    <input type="radio" class="border-gray-700 checked:bg-gray-900 checked:border-transparent" v-model="markupType" value="dollar" :checked="markupType === 'dollar'">
                                    <span class="ml-1 text-gray-700 text-xs">$ Dollar</span>
                                </label>
                            </div>
                            <button class="uppercase rounded bg-purple-900 text-white text-sm py-1 px-2" type="submit">Save</button>
                        </div>
                    </form>
                </div>
            </template>
        </modal>
        <div class="flex mb-4">
            <a href="/organization/store" class="flex justify-cetner items-center
            bg-purple-900 text-white px-4 py-2 text-center">
                <span class="material-icons-outlined">
                chevron_left
                </span>
                Back to Store Setup</a>
            <div class="p-8 px-4 w-full lg:w-8/12 rounded ml-4 mr-4 text-lg"></div>
        </div>
    </div>
</template>
<script>
import Modal from "../ui/Modal.vue";
import BaseProductCard from "../base-products/BaseProductCard"

export default {
    props: [
        'artwork',
        'products',
        'settings'
    ],
    components: {
        BaseProductCard,
        Modal
    },
    data() {
        return {
            enabled: this.settings.enabled,
            markup: this.settings.markup,
            markupType: this.settings.markupType,
            productArray: [],
            productsAdded: false,
            buttonWidth: null,
        }
    },
    computed: {
        disableSelection: function() {
            return this.productArray.length >= 6;
        }
    },
    mounted() {
        this.open()
    },
    watch: {
        /**productsArray: function () {
            if (this.length > 0) {
                this.calculateWidth()
            }
        }*/
    },
    methods: {
        added: function(id) {
            let product = this.productArray.indexOf(id);

            if (product > -1) {
                this.productArray.splice(color, 1)
            } else {
                this.productArray.push(id)
            }
        },
        calculateWidth: function() {
            console.log(this.$refs.saveButton.offsetWidth)
            return '-' + this.$refs.saveButton.offsetWidth + 'px';
        },
        open: function() {
            if (!this.enabled) {
                this.$refs.modalName.openModal();
            }
        },
        saveSettings() {
            let request = {
                enabled: this.enabled,
                markup: this.markup,
                markupType: this.markupType
            }

            axios.post('/organization/store', request)
                .then(resp => {
                    this.$toasted.show(resp.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-green text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                    setTimeout(() => {
                        this.$refs.modalName.closeModal();
                    }, 2000)
                }).catch(err => {
                this.$toasted.show(err, {
                    theme: "",
                    position: "bottom-right",
                    className: "bg-red text-white rounded-full",
                    type: "error",
                    duration: 5000,
                })
            })
        }
    }

}
</script>
<style>
.toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #68D391;
}
.fixed-center {
    bottom: 30px;
    left: 50%;
}
</style>
