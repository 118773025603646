<template>
    <div>
        <div class="md:flex py-4 lg:py-8">
            <h4 class="text-purple-900 text-xl flex-auto md:px-6">
                All Donations
            </h4>
            <form @submit.prevent="search" class="md:flex-auto flex mb-2 md:mb-0">
                <input class="appearance-none border border-r-0 w-full py-2 px-3 text-gray-700 text-xxs uppercase tracking-wide leading-5
            focus:outline-none focus:shadow-outline placeholder-current" name="search:" v-model="searchTerm"
                       type="text" placeholder="Search">
                <button type="button" @click.prevent="search" class="border border-l-0 px-2 text-sm text-gray-500">
                    <span class="material-icons-outlined align-middle">
                    search
                    </span>
                </button>
            </form>
            <donor-list-filters :campaigns="campaigns" @filterChange="filter"></donor-list-filters>
            <button type="buttom" class="py-1 px-2 text-xxs bg-gray-400 uppercase"
                    :class="[exporting ?  'bg-blue-400 text-white' : 'bg-gray-400']"
                    @click="exportList" v-html="buttonText"></button>
        </div>
        <div class="md:flex py-4 lg:py-8">
            <div class="md:flex px-4 mb-2 md:mb-0 py-2 border border-grey-500 bg-gray-200 uppercase text-xxs leading-5 tracking-wide">
                <div>Total Donors: {{ donationsStats.donorTotal }}</div>
                <span class="mx-6">|</span>
                <div>Dollar amount of Donations: ${{ donationsStats.donationAmount }}</div>
                <span class="mx-6">|</span>
                <div>Recurring Donations : {{ donationsStats.recurringTotal }}</div>
                <span class="mx-6">|</span>
                <div>Total Active Recurring : {{ donationsStats.activeRecurringDonations }}</div>
            </div>
        </div>
        <div class="w-full text-right hidden"><a id="export" :href="exportFile">Download Export</a></div>
        <div class="overflow-x-scroll">
            <table class="min-w-full">
                <thead>
                <tr class="">
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Email</th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Donation</th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Recurring</th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Order</th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Times Donated</th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Manage
                    </th>
                </tr>

                </thead>
                <tbody class="bg-white">
                <tr v-for="(donation, index) in donationsArray">
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex items-center">
                            <div class="text-sm leading-5 font-medium text-gray-900">{{ donation.customer.name }}</div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex items-center">
                            <div class="text-sm leading-5 font-medium text-gray-900">{{ donation.customer.email }}</div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex items-center">
                            <div class="text-sm leading-5 font-medium text-gray-900">{{ donation.amount }}</div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex items-center">
                            <div class="text-sm leading-5 font-medium text-gray-900">{{ donation.donation_date }}</div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex items-center">
                            <div class="text-sm leading-5 font-medium text-gray-900">{{ donation.parent_name }}</div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex items-center">
                            <div class="text-sm leading-5 font-medium text-gray-900">{{ donation.is_recurring }}</div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex items-center">
                            <div class="text-sm leading-5 font-medium text-gray-900">
                                <a href="#" v-if="donation.order_amount" @click.prevent="showDonation(donation)">
                                    {{ donation.order_amount }}
                                </a>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex items-center">
                            <div class="text-sm leading-5 font-medium text-gray-900">{{ donation.count }}</div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div class="flex justify-center">
                            <a class="inline-block" href="#" @click.prevent="showDonation(donation)">
                                <svg class="fill-current text-blue-400 hover:text-blue-600 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/>
                                </svg>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <donation-show-view :donation_id="donation_id" :showDonationInfo="showDonationInfo" @closed-panel="donation_id = null; showDonationInfo = false;" :class="[showDonationInfo ? 'visible' : 'invisible']"
        ></donation-show-view>

    </div>
</template>
<script>
    import DonorListFilters from "./partials/DonorListFilters"
    import DonationShowView from './partials/DonationShowView.vue';
    export default {
        props: ['donations', 'campaigns', 'stats'],

        components: {
            DonorListFilters,
            DonationShowView,
        },

        data() {
            return  {
                donationsArray: this.donations,
                donationsStats: this.stats,
                filtersArray: [],
                searchTerm: null,
                exportFile: '',
                exporting: false,
                buttonText: 'Export Report',
                donation_id:null,
                showDonationInfo: false,
            }
        },
        watch: {
            searchTerm: function (val, oldVal) {
                if (this.searchTerm !== "") return;
                event = {
                    'type': 'clear'
                };
                this.filter(event)
            }
        },
        methods: {
            search() {
                let request = [
                    {
                        type: 'search',
                        value: this.searchTerm,
                    }
                ]
                axios.post('/organization/donors', request)
                    .then(response => {
                        this.donationsArray = response.data.donations;
                        this.donationsStats = JSON.parse(response.data.stats);
                    }).catch(error => {
                        console.log(error)
                    })
            },
            showDonation(donation) {
                this.showDonationInfo = true;
                this.donation_id = donation.id;
            },
            filter(event) {
                if (event.type === 'clear') {
                    this.filtersArray = []
                } else {
                    this.filtersArray = event;
                }

                let request = this.filtersArray;

                axios.post('/organization/donors', request)
                    .then(response => {
                        this.donationsArray = response.data.donations;
                        this.donationsStats = JSON.parse(response.data.stats);
                        console.log(response.data)
                    }).catch(error => {
                        console.log(error)
                    })
            },
            exportList() {
                this.exporting = true;
                this.buttonText = 'Exporting';
                axios.get('/organization/donors/export')
                    .then(response => {
                        this.exportFile = response.data.file;
                        window.open(response.data.file, '_self')
                        setTimeout(() => {
                            this.buttonText = 'Export Report';
                            this.exporting = false;
                        }, 1000)
                    }).catch(error => {
                    console.log(error)
                })
            }
        }
    }
</script>
