<template>
    <form id="form" enctype="multipart/form-data" @submit.prevent="update">
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="flex-auto block lg:inline-block w-full lg:w-auto text-lg text-purple-900 uppercase">General Settings</span>
                <span class="block lg:inline-block flex-auto w-full lg:w-10/12 border-b"></span>
            </legend>
            <div class="mb-8">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="name">
                    Organization Name
                </label>
                <input class="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight
            focus:outline-none focus:shadow-outline" type="text" id="name" name="name" v-model="name">
            </div>
            <div class="mb-12">
                <label class="block text-gray-700 text-sm font-bold mb-6">
                    Organization Logo
                </label>
                <image-uploader v-model="logo">
                    <template v-slot:preview>
                        <img v-if="logo.imageURL" class="w-1/4 mb-4" :src="logo.imageURL" alt="avatar">
                    </template>
                    <template slot="activator">
                        <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs">
                            Choose Image
                            <svg class="inline-block float-right h-4 w-4 align-middle ml-2"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                            20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#fff"/></svg>
                        </button>
                    </template>
                </image-uploader>
            </div>
            <div class="mb-12">
                <label class="block text-gray-700 text-sm font-bold mb-6">
                    Organization Page Background Banner (1920x1080)
                </label>
                <image-uploader v-model="banner">
                    <template v-slot:previewr>
                        <img v-if="banner.imageURL" class="w-1/4" :src="banner.imageURL" alt="avatar">
                    </template>
                    <template v-slot:activator>
                        <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs">
                            Choose Image
                            <svg class="inline-block float-right h-4 w-4 align-middle ml-2"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                            20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#fff"/></svg>
                        </button>
                    </template>
                </image-uploader>
            </div>
        </fieldset>

        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="flex-auto">Transaction Settings</span>
                <span class="flex-auto border-b w-10/12"></span>
            </legend>
            <div class="mb-4">
                <label class="md:w-2/3 block text-gray-700 text-sm font-bold">
                    <input class="mr-2 leading-tight" type="checkbox" name="allow_recurring_donations" v-model="allow_recurring_donations">
                    Recurring Donations <span class="inline-block px-1 font-normal">|</span>
                    <span class="text-sm text-gray-500 font-normal italic">
                        Allow donors to select recurring donations
                    </span>
                </label>
            </div>
            <div class="mb-4">
                <label class="md:w-2/3 block text-gray-700 text-sm font-bold">
                    <input class="mr-2 leading-tight" type="checkbox" name="has_credit_fee" v-model="has_credit_fee">
                    Credit Fee <span class="inline-block px-1 font-normal">|</span>
                    <span class="text-sm text-gray-500 font-normal italic">
                        Should the credit processing fee be passed along to the customer?
                    </span>
                </label>
            </div>
            <div class="mt-12 mb-4">
                <label class="md:w-2/3 block text-gray-700 text-sm font-bold mb-2">
                    Default Donation Amounts<span class="inline-block px-1 font-normal">|</span>
                    <span class="text-sm text-gray-500 font-normal italic">
                        These are the default donation amounts. Add or delete using the options below.
                    </span>
                </label>
                <div class="flex flex-wrap m-1 w-1/5 text-center">
                    <button v-for="(amount, index) in donation_amounts" type="button" @click="removeAmount(index)" class="rounded bg-purple-900 text-white py-1 px-2 w-auto mr-2 mb-2 inline-flex items-center">
                        <span class="w-3/4">${{ amount }}</span>
                        <svg class="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" fill="#fff"/></svg>
                    </button>
                </div>
                <input type="text" class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline w-1/12" v-model.number="tempAmount">
                <button type="button" @click="addAmount" class="rounded text-white bg-teal-400 uppercase py-2 px-3 text-sm inline-flex items-center">
                    <span class="mr-2">&plus;</span>
                    <span>Add Option</span>
                </button>
            </div>
        </fieldset>

        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="flex-auto">Payment Processor Settings</span>
                <span class="flex-auto border-b w-10/12"></span>
            </legend>
            <div class="mb-4 flex justify-between items-center">
                <div class="w-2/3">
                    <svg class="h-20 w-20 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 149.8">
                        <path fill="#32325d"
                              d="M360 77.4c0-25.6-12.4-45.8-36.1-45.8s-38.2 20.2-38.2 45.6c0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5V96a53.1 53.1 0 0 1-24.5 5.5c-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16zm-63.5-36.3a28.25 28.25 0 0 0-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4zm-71.3-74.8l25.1-5.4V0l-25.1 5.3zm0 7.6h25.1v87.5h-25.1zm-26.9 7.4l-1.6-7.4h-21.6v87.5h25V61.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4zm-50-29.1l-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V99c-3.2 1.3-19 5.9-19-8.9V54.6h19V33.3h-19zM25.3 58.7c0-3.9 3.2-5.4 8.5-5.4a55.78 55.78 0 0 1 24.8 6.4V36.2a66 66 0 0 0-24.8-4.6C13.5 31.6 0 42.2 0 59.9 0 87.5 38 83.1 38 95c0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8a69.32 69.32 0 0 0 27.3 5.7c20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z"/>
                    </svg>
                    <span class="inline-block px-1 font-normal">|</span>
                    <span class="text-sm text-gray-500 font-normal italic">Accept credit card payments in a cinch.</span>
                    <stripe-connect v-if="stripe.enabled" :stripe="stripe" :connect="connect"></stripe-connect>
                </div>
                <div>button
                    <label class="checkbox-container checkbox-right">
                        <input type="checkbox" v-model="stripe.enabled"/>
                        <span class="label">Enable</span>
                    </label>
                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="flex-auto">Social Integrations</span>
                <span class="flex-auto border-b w-10/12"></span>
            </legend>

            <social-setting :service="facebook"></social-setting>
            <social-setting :service="twitter"></social-setting>
            <social-setting :service="google"></social-setting>
        </fieldset>
        <button class="uppercase rounded bg-purple-900 text-white py-1 px-2" type="submit">Update Settings</button>
    </form>
</template>
<script>
    import ImageUploader from "../ImageUploader";
    import SocialSetting from "../settings/partials/SocialSetting";
    import StripeConnect from "../settings/StripeConnect";

    export default {
        props: [
            'organization',
            'settings',
            'services',
            'connect'
        ],

        components: {
            ImageUploader,
            SocialSetting,
            StripeConnect
        },

        data() {
            return {
                name: this.organization.name,
                logo: {
                  imageFile: null,
                  imageURL: (this.organization.logo) ? this.organization.logo.path : null
                },
                banner: {
                    imageFile: null,
                    imageURL: (this.organization.banner) ? this.organization.banner.path : null
                },
                has_credit_fee: this.settings.has_credit_fee,
                allow_recurring_donations: this.settings.allow_recurring_donations,
                donation_amounts: this.settings.donation_amounts,
                tempAmount: null,
                stripe: this.services.stripe,
                facebook: this.services.facebook,
                twitter: this.services.twitter,
                google: this.services.google,
            }
        },
        computed: {
            logoUrl: function() {
                if (this.logo !== null) {
                    return this.logo.imageURL
                } else if (this.organization.logo) {
                    return this.organization.logo.path
                }

                return null;
            }
        },
        created() {
        },
        methods: {
            removeAmount: function(index) {
                this.donation_amounts.splice(index, 1);
            },
            addAmount: function() {
                if (!this.tempAmount) return;
                this.donation_amounts.push(this.tempAmount);
                this.tempAmount = null;
            },
            update: function() {
                let request = {
                    name: this.name,
                    has_credit_fee: this.has_credit_fee,
                    allow_recurring_donations: this.allow_recurring_donations,
                }
                var form = document.getElementById('form');
                let formData = new FormData();
                formData.append('_method', 'PUT')
                Object.entries(request).map(([prop, val]) => {
                    formData.append(prop, val)
                })
                formData.append('donation_amounts', JSON.stringify(this.donation_amounts))
                formData.append('facebook', JSON.stringify(this.facebook))
                formData.append('twitter', JSON.stringify(this.twitter))
                formData.append('google', JSON.stringify(this.google))

                if (this.logo.imageFile !== null) {
                    formData.append('logoFile', this.logo.imageFile);
                }
                if (this.banner.imageFile !== null) {
                    formData.append('bannerFile', this.banner.imageFile)
                }

                axios.post('/organization/settings/', formData)
                    .then(resp => {
                        this.$toasted.show(resp.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-green text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                    }).catch(err => {
                        this.$toasted.show(err, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-red text-white rounded-full",
                            type: "error",
                            duration: 5000,
                        });
                });
            }
        }
    }
</script>
