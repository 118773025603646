<template>
    <div class="fixed top-0 bottom-0 right-0
        w-4/5 md:w-3/5 transform shadow-xl overflow-y-scroll border-l border-black-200 bg-white p-6 z-50 text-left">
        <header class="px-4"></header>
            <button type="button" class="text-sm mb-4 mr-2" @click="closePanel">X Close</button>
            <h1 class="block mb-6 font-bold text-xl text-purple-400">
                <span v-if="!donation">Loading Donation....</span>
                <span v-if="donation">Donation Details</span>
            </h1>
        </header>
        <div class="p-4 pb-8" v-if="donation">
            <div class="grid grid-cols-2 gap-4">
                <div v-for="row in donationCols" v-if="donation" class="my-4">
                    {{ row.title }} : 
                    <span v-if="row.hasOwnProperty('subCol')">
                        {{ donation[row.col][row.subCol] ? donation[row.col][row.subCol] : '' }}
                    </span>
                    <span v-if="!row.hasOwnProperty('subCol')">
                        {{ donation[row.col] ? donation[row.col] : '' }}
                    </span>
                </div>
            </div>
        </div>
        <!-- this div might not be nessessarry -->
        <div v-if="donation">
            <div v-for="order in donation.orders">
            <hr  class="p-4 pb-4" />

            <div class="p-4 pb-4">
                <h3 class="underline font-bold mb-4 text-purple-900 justify-center mb-2" v-if="donation">
                    Order Details
                </h3>
                <div class="grid grid-cols-2 gap-4">
                    <div v-for="(col, index) in orderCols" class="my-4">
                        {{ index }} : {{ order.hasOwnProperty(col) ? (order[col] ? order[col] : "" ) : "" }}
                    </div>

                    <div class="my-4"></div>

                    <div class="my-4">
                        <b>Order Total: {{ order.total }}</b>
                    </div>
                </div>

                <div class=" mb-2">
                    <h5 class="underline font-bold mt-4 text-purple-900" v-if="donation">
                        Order Items
                    </h5>

                        <table class="min-w-full mt-2" v-if="order.order_items">
                            <thead>
                                <tr>
                                    <th v-for="col in orderItemsCols" class="justify-center whitespace-no-wrap border border-black-200">{{ col.title }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, itemIndex) in order.order_items">
                                    <td v-for="(row, index) in orderItemsCols" class="justify-center whitespace-no-wrap border border-black-200">
                                        <span v-if="row.hasOwnProperty('subCol')">
                                            {{ item[row.col][row.subCol] ? item[row.col][row.subCol] : '' }}
                                        </span>
                                        <span v-if="!row.hasOwnProperty('subCol')">
                                            {{ item[row.col] ? item[row.col] : '' }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <hr v-if="donation" class="p-4 pt-4"/>

        <div class="p-4 p4-8">
            <h3 class="underline font-bold mb-4 text-purple-900 justify-center mb-2" v-if="donation">
                Recent Donations from {{ donation.customer.name }} :
            </h3>
            <table class="min-w-full" v-if="donation">
                <thead>
                    <tr>
                        <th v-for="(col, index) in donationBreakdownCols" class="justify-center whitespace-no-wrap border border-black-200">
                            {{ index }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(customerDonation, donationIndex) in customerDonations">
                        <td v-for="(col, index) in donationBreakdownCols" class="justify-center whitespace-no-wrap border border-black-200">
                            {{ customerDonation.hasOwnProperty(col) ? (customerDonation[col] ? customerDonation[col] : "" ) : "" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['showDonationInfo', 'donation_id'],

        data() {
            return {
                donation:null,
                donationCols : [
                    {'title' : 'Campaign', 'col': 'behalf', 'subCol':'name'},
                    {'title' : 'Amount', 'col': 'amount'},
                    {'title' : 'Note', 'col': 'note'},
                    {'title' : 'Date of Donation', 'col': 'donation_date'},
                    {'title' : 'Recurring', 'col': 'is_recurring'},
                    {'title' : 'Donor Name', 'col': 'customer', 'subCol':'name'},
                    {'title' : 'Donor Email', 'col': 'customer', 'subCol':'email'},
                    {'title' : 'Shipping Address', 'col': 'customer', 'subCol':'shipping_address'},
                    {'title' : 'Billing Address', 'col': 'customer', 'subCol':'billing_address'},
                    {'title' : 'Total Donor Donations', 'col': 'customer', 'subCol':'total_donations'},
                ],
                orderCols : {
                    'Subtotal': 'subtotal',
                    'Shipping' : 'shipping',
                    'Discount' : 'discount_amount',
                    'Additional Fees' : 'fees',
                },
                orderItemsCols : [
                    {'title' : 'Name', 'col': 'product', 'subCol':'name'},
                    {'title' : 'Price', 'col': 'price'},
                    {'title' : 'Type', 'col': 'product', 'subCol':'type'},
                ],
                donationBreakdownCols : {
                    'Donation' : 'amount',
                    'Date' : 'donation_date',
                    'Campaign' : 'parent_name',
                    'Recurring' : 'is_recurring',
                },
            }
        },
        watch: {
            showDonationInfo : function() {
                if (this.showDonationInfo) {
                    this.showDonation();
                }
            }
        },
        methods: {
            showDonation() {
                axios.post('/organization/donor/donation/' + this.donation_id)
                    .then(response => {
                        this.donation = response.data.donation;
                        this.customerDonations = response.data.customerDonations;
                    }).catch(error => {
                        console.log(error)
                    }
                );
            },
            closePanel() {
                this.donation = null;
                this.$emit('closed-panel')
            }
        }
    }
</script>
