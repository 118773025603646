<script>
    import { Bar } from 'vue-chartjs';

    export default {

        extends: Bar,

       props: {
            chartdata: {
                type: Object,
                default: null
            }
        },

        data: () => ({
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            display: true,
                            stepSize: 2
                        },
                        gridLines: {
                            drawTicks: true,
                            display: true,
                        }
                    }],
                },
                responsive: true,
                maintainAspectRatio: false
            }
        }),

        mounted() {
            this.renderChart(this.chartdata, this.options)
        }
    }
</script>
