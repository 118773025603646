<template>
    <div class="card mx-auto w-full">
        <div class="">
            <h2 class="text-5xl font-light mb-2">Sign Up</h2>
            <div class="text-right mb-2">
                <span class="inline-block rounded-full mr-2 h-2 w-6 bg-gray-700"></span>
                <span class="inline-block rounded-full mr-2 h-2 w-6" :class="[step >= 2 ? 'bg-gray-700' : 'bg-gray-400']"></span>
                <span class="inline-block rounded-full mr-2 h-2 w-6" :class="[step >= 3 ? 'bg-gray-700' : 'bg-gray-400']"></span>
                <span class="inline-block rounded-full mr-4 h-2 w-6" :class="[step >= 4 ? 'bg-gray-700' : 'bg-gray-400']"></span>
                <span class="inline-block font-bold text-gray-700 text-lg">Step {{ step }} of 4</span>
            </div>
        </div>

        <div class="card-body">
            <form id="registerForm" @submit.prevent="submit" class="bg-purple-900 shadow-md rounded-md px-8 pt-12 pb-8 mb-4">
                <div v-if="step === 1">
                    <div class="md:flex" :class="[$v.lastName.$error || $v.firstName.$error ? 'mb-2' : 'mb-8']">
                        <div class="w-full md:w-1/2 mb-8 md:mr-8 md:mb-0">
                            <label for="first_name" class="sr-only block text-white font-bold mb-2">First Name</label>
                            <input id="first_name" type="text" class="shadow appearance-none border rounded w-full py-6
                                            px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                            placeholder-current text-lg" name="first_name" v-model.trim="$v.firstName.$model"
                                   required autocomplete="name" autofocus placeholder="First Name">

                            <span v-if="$v.firstName.$error" class="text-red-500 inline-block text-sm" role="alert">
                                <strong>Field is required.</strong>
                            </span>
                        </div>
                        <div class="w-full md:w-1/2 mb-8 md:mb-0">
                            <label for="last_name" class="sr-only block white font-bold mb-2">Last Name</label>
                            <input id="last_name" type="text" class="shadow appearance-none border rounded w-full py-6
                                             px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                             placeholder-current text-lg" name="last_name" v-model="$v.lastName.$model"
                                   required autocomplete="name" autofocus placeholder="Last Name">

                            <span v-if="$v.lastName.$error" class="text-red-500 inline-block text-sm" role="alert">
                                <strong>Field is required.</strong>
                            </span>
                        </div>
                    </div>
                    <div :class="[$v.email.$error || $v.email.$error ? 'mb-2' : 'mb-8']">
                        <label for="email" class="sr-only block text-white font-bold mb-2">Email</label>
                        <input id="email" type="email" class="shadow appearance-none border rounded w-full py-6 px-3
                                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                        placeholder-current text-lg" name="email" v-model="$v.email.$model"
                               required autocomplete="email" placeholder="Email">

                        <span v-if="$v.email.$error" class="text-red-500 inline-block text-sm" role="alert">
                            <strong>Field is required and must be a valid email.</strong>
                        </span>
                    </div>
                    <div :class="[$v.password.$error || $v.password.$error ? 'mb-2' : 'mb-8']">
                        <div style="display:flex;align-items:center;position:relative;">
                            <label for="password" class="sr-only block text-white font-bold mb-2">Password</label>
                            <input id="password" :type="showPassword ? 'text' : 'password'" class="shadow appearance-none border rounded w-full py-6 px-3
                                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                        placeholder-current text-lg form-control" name="password" v-model="$v.password.$model"
                               @input="$v.password.$reset()" required placeholder="Choose Password">
                               <span class="material-icons-outlined" style="position:absolute;right:10px;cursor:pointer;" 
                                    @click="changePasswordInput" >visibility</span>
                        </div>

                        <span v-if="$v.password.$error" class="text-red-500 inline-block text-sm" role="alert">
                            <strong> {{ errorMessage }} </strong>
                        </span>
                    </div>
                    <modal ref="registeredModal" :classList="classList">
                        <template slot="header">
                            <div class="bg-purple-900 w-full py-2 px-4">
                                <h3 class="text-2xl text-white bold">Already Registered?</h3>
                            </div>
                        </template>
                        <template v-slot:body>
                            <div class="p-6 overflow-hidden">
                                <p class="mb-2 text-xl">
                                    This email is already registered with EventForge, <a class="underline text-purple-600" :href="'/staff/login/?email='+ email">click here</a> to sign in.
                                </p>
                            </div>
                        </template>
                    </modal>
                </div>

                <div v-if="step === 2">
                    <p class="text-2xl font-bold text-white uppercase mb-4">Fundraiser Details</p>
                    <div :class="[$v.organization.$error || $v.organization.$error ? 'mb-2' : 'mb-8']">
                        <label for="organization" class="sr-only block text-white text-sm font-bold mb-2">Organization Name</label>
                        <input id="organization" type="text" class="shadow appearance-none border rounded w-full py-6 px-3
                                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                        placeholder-current text-lg"
                               name="name" v-model="$v.organization.$model" required autocomplete="company name" autofocus placeholder="Organization Name">
                        <span v-if="$v.organization.$error" class="text-red-500 inline-block text-sm" role="alert">
                            <strong>Field is required.</strong>
                        </span>
                    </div>

                    <div class="mb-2">
                        <label for="title" class="sr-only block text-white text-sm font-bold mb-2">Title of Fundraiser</label>
                        <input id="title" type="text" class="shadow appearance-none border rounded w-full py-6 px-3
                                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                        placeholder-current text-lg"
                               name="name" v-model="fundraiserTitle" required placeholder="Title of Fundraiser">

                        <span class="text-red-500 inline-block mt-2 text-sm" role="alert">
                            <strong></strong>
                        </span>
                    </div>
                    <div class="mb-2">
                        <label for="goal" class="sr-only block text-white text-sm font-bold mb-2">Fundraising Goal Amount</label>
                        <div class="flex">
                            <span class="material-icons-outlined inline-block rounded rounded-r-none bg-purple-100 border
                            border-r-0 py-6 px-3 text-lg leading-6 text-center">
                                attach_money
                            </span>
                            <input id="goal" type="text" class="appearance-none border border-l-0 rounded rounded-l-none w-full py-6 px-3
                                        text-gray-700 leading-tight focus:bg-white focus:outline-none
                                        placeholder-current text-lg"
                                   name="goal" v-model.number="goal" @blur="formatGoal" required placeholder="Fundraising Goal Amount">
                        </div>
                        <span class="text-red-500 inline-block mt-2 text-sm" role="alert">
                            <strong></strong>
                        </span>
                    </div>

                    <div class="mb-2">
                        <label for="message" class="sr-only block text-white text-sm font-bold mb-2">Campaign Page Message</label>
                        <textarea id="message" type="text" class="shadow appearance-none border rounded w-full py-6 px-3
                                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                        placeholder-current text-lg"
                                  name="message" v-model="message" placeholder="Campaign Page Message"></textarea>

                        <span class="text-red-500 inline-block mt-2 text-sm" role="alert">
                            <strong></strong>
                        </span>
                    </div>
                </div>

                <div v-if="step === 3">
                    <p class="text-2xl font-bold text-white uppercase mb-4">Campaign Visuals</p>

                        <image-uploader v-model="logo" class="mb-6">
                            <div slot="activator" class="flex bg-white shadow border rounded w-full py-6 px-3
                                        text-gray-700 leading-tight text-lg cursor-hover">
                                <div class="flex-1">
                                    <span class="mb-2 block">Campaign Logo</span>
                                    <img v-if="logo.imageURL" class="w-32" :src="logo.imageURL" alt="avatar">
                                </div>

                                <div class="">
                                    <button type="button" class="align-middle text-xs">
                                        <span class="material-icons-outlined">
                                        file_upload
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </image-uploader>
                    <p class="text-purple-300 text-lg mb-2">Print Logo Requirements and Tips:</p>
                    <p class="text-white text-lg">If you want to include your logo on apparel like most users do,
                    you must upload a high-resolution logo file.</p>
                    <hr class="bg-white my-4">

                    <div class="flex">
                        <div class="w-1/2">
                            <ul class="list-disc text-white">
                                <li class="ml-2 mb-4">
                                    Recommend minimum width 3000px
                                </li>
                                <li class="ml-2 mb-4">
                                    Logo should have a transparent background so it doesn't appear as a block on the apparel
                                    unless that is your preference. Accepted file types with transparent backgrounds are .png.
                                </li>
                                <li class="ml-2 m-4">
                                    Eventforge Lite cannot offer returns on apparel sales so purchases are non-refundable.
                                    For this reason, it is important that your logo file meets the requirements listed
                                    here so it does not print poorly on the items sold.
                                </li>
                            </ul>
                        </div>
                        <div class="w-1/2">
                            <ul class="list-disc text-white">
                                <li class="ml-2">
                                    If you would like to offer dark and light shirts with your logo or artwork, keep in
                                    mind that a dark-colored logo will not show well on a dark-colored shirt and likewise with
                                    light-colored logos and apparel. We offer the option to upload logo variations to include
                                    a light, dark and other logo variations for this purpose in your settings page.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="my-4 text-white text-xl">
                        <label>
                            <input type="checkbox" v-model="logoRequirements" /> I have read and understand the logo requirements above.
                        </label>
                        <span v-if="$v.logoRequirements.$error" class="text-red-500 inline-block text-sm" role="alert">
                            <strong>Plese acknowledge the you have read and understand.</strong>
                        </span>
                    </div>
                    <div class="mb-2">
                        <image-uploader v-model="banner" class="mb-6">
                            <div slot="activator" class="flex bg-white shadow border rounded w-full py-6 px-3
                                        text-gray-700 leading-tight text-lg cursor-hover">
                                <div class="flex-1 block">
                                    <span class="mb-2">Campaign Banner</span>
                                    <img v-if="banner.imageURL" class="w-32" :src="banner.imageURL" alt="avatar">
                                </div>
                                <div>
                                    <button type="button" class="align-middle text-xs">
                                        <span class="material-icons-outlined">
                                        file_upload
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </image-uploader>
                    </div>
                    <ul class="list-disc list-outside ml-6 text-white">
                        <li class="mb-2 w-1/2">Recommended minimum width 1800px, File size limit is 3MB</li>
                    </ul>
                </div>
                <!-- end step 3 -->

                <div class="form-group row mt-2">
                    <div class="md:flex">
                        <div v-if="step === 1" class="w-full md:w-1/2 mb-4 md:mb-0 text-white">
                            <p class="text-purple-300 text-lg mb-2">Password must:</p>
                            <ul class="list-disc list-outside ml-6 text-sm italic">
                                <li>Be 10 Characters</li>
                                <li>Contain 1 uppercase letter</li>
                                <li>Contain 1 lowercase letter</li>
                                <li>Contain 1 number</li>
                            </ul>
                        </div>
                        <div v-if="step === 2" class="w-full md:w-1/2 mb-4 md:mb-0 text-white">
                            <p class="text-purple-300 text-lg mb-2">Tips for your message:</p>
                            <ul class="list-disc list-outside ml-6 text-sm italic">
                                <li class="mb-2">Be brief but provide enough detail so visitors understand
                                    why they should help fund or donate.</li>
                                <li class="mb-2">You are limited to 1200 words</li>
                                <li>Add bulleted lists, small images, graphics, and anything else
                                    you believe will help visitors</li>
                            </ul>
                        </div>
                        <div v-if="step === 3 || step === 4" class="w-full md:w-1/2 mb-4 md:mb-0 text-white">
                        </div>
                        <div class="w-full md:w-1/2 text-right">
                            <button v-if="step < 3" type="button" class="w-full md:w-auto right-0 py-2 px-8 rounded-full bg-yellow-400 text-purple-900 text-lg uppercase"
                                    @click.prevent="next">
                                Next Step
                            </button>
                            <button v-else type="submit" @click.prevent="submit" class="w-full md:w-auto right-0 py-2 px-8 rounded-full bg-yellow-400 text-purple-900 text-lg uppercase">
                                Complete Sign Up
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <a v-if="step > 1" href="#" @click.prevent="prev" class="cursor-hover leading-4 underline">&lt; Previous Step</a>
        </div>
    </div>
</template>
<script>
    import ImageUploader from "../ImageUploader";
    import Modal from "../ui/Modal";
    import { required, requiredIf, email, helpers } from 'vuelidate/lib/validators';
    import formatCurrency from "../../mixins/formatCurrency";

    const hasLowercase = (value) => {
        return  value.search(/[a-z]/) > -1
    }
    const hasUppercase = (value) =>{
        return value.search(/[A-Z]/) > -1
    }
    const hasNumber = (value) => {
        return value.search(/[0-9]/) > -1
    }
    const checked = (value) => {
        return value;
    }
    const hasTen = (value) => {
        return value.length >= 10
    }

    export default {

        components: {
            ImageUploader,
            Modal
        },
        mixins: [formatCurrency],
        data() {
            return {
                step: 1,
                error: false,
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                organization: '',
                fundraiserTitle: '',
                errorMessage: '',
                goal: '',
                showPassword: false,
                message: '',
                logo: {
                    imageFile: null,
                    imageURL: null
                },
                banner: {
                    imageFile: null,
                    imageURL: null
                },
                background: null,
                logoRequirements: false,
                classList: {
                    width: 'w-2/5',
                    top: 'mt-40'
                }
            }
        },
        validations: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            email: {
                required,
                email
            },
            password: {
                required,
                upper: hasUppercase,
                lower: hasLowercase,
                number: hasNumber,
                length: hasTen
            },
            stepOneGroup: ['firstName', 'lastName', 'email', 'password'],
            organization: {
                required
            },
            stepTwoGroup: ['organization'],
            logoRequirements: {
                isChecked: checked
            },
            stepThreeGroup: ['logoRequirements']
        },
        mounted() {
        },
        methods: {
            async next() {
                if (this.step === 1) {
                    this.$v.stepOneGroup.$touch();
                    this.errorMessage = '';

                    if (this.$v.stepOneGroup.$invalid) {
                        this.errorMessage = this.buildErrorMessage();

                        return;
                    }

                    const registered = await this.validateEmail()
                    if (registered) {
                        this.$refs.registeredModal.openModal();
                        return;
                    }
                }

                if (this.step === 2) {
                    this.$v.stepTwoGroup.$touch();
                    if (this.$v.stepTwoGroup.$invalid) return;
                }

                this.step = this.step + 1
            },
            prev() {
                this.step = this.step - 1
            },
            formatGoal() {
                this.goal = this.formatCurrency(this.goal)
            },
            buildErrorMessage() { 
                let errorMessageBuilder = 'Password ';
                let passwordErrors = this.$v.stepOneGroup.password;

                ['required', 'length', 'lower', 'upper', 'number'].forEach(function (key) {
                    if (passwordErrors[key] == true) {
                        return;
                    }

                    let message = '';

                    switch (key) {
                        case 'required':
                            message = 'is required';
                            break;
                        case 'length':
                            message = 'must have 10 characters';
                            break;
                        case 'lower':
                            message = 'a lowercase letter';
                            break;
                        case 'upper':
                            message = 'an uppercase letter';
                            break;
                        case 'number':
                            message = 'a number';
                            break;
                    }

                    if (['lower', 'upper', 'number'].includes(key) && !errorMessageBuilder.includes('contain')) {
                        message = ' contain ' + message;

                        if (!errorMessageBuilder.includes('must')) {
                            message = ' must ' + message
                        }
                    }

                    if (errorMessageBuilder != 'Password ') {
                        message = ', ' + message;
                    } 

                    errorMessageBuilder = errorMessageBuilder + message;
                });

                let lastCommaIndex = errorMessageBuilder.lastIndexOf(',');

                if (lastCommaIndex >= 0) {
                    errorMessageBuilder = errorMessageBuilder.substring(0,lastCommaIndex) + ' and ' + errorMessageBuilder.substring(lastCommaIndex + 1);

                    if (errorMessageBuilder.includes('and   contain')) {
                        let lastContainIndex = errorMessageBuilder.lastIndexOf('contain');

                        errorMessageBuilder = errorMessageBuilder.substring(0, lastContainIndex) + ' must ' + errorMessageBuilder.substring(lastContainIndex);
                    }
                }

                return errorMessageBuilder;
            },
            changePasswordInput() {
                this.showPassword = !this.showPassword;
            },
            async validateEmail() {
                let request = {
                    email: this.email
                }
                return await axios.post('/staff/validate-email', request)
                    .then(response => {
                        if (response.data.registered === 'yes') {
                            return true
                        }
                        return false
                    }).catch(error => {
                    this.$toasted.show(error, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-red text-white rounded-full",
                        type: "error",
                        duration: 5000,
                    });
                })
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.error = true
                    return false;
                }

                this.error = false;

                let request = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email: this.email,
                    password: this.password,
                    org_name: this.organization,
                    fundraiserTitle: this.fundraiserTitle,
                    goal: this.goal,
                    message: this.message,
                }

                var form = document.getElementById('registerForm');
                let formData = new FormData(form);
                Object.entries(request).map(([prop, val]) => {
                    formData.append(prop, val)
                })

                if (this.banner.imageFile !== null) {
                    formData.append('hero', this.banner.imageFile)
                }

                if (this.logo.imageFile !== null) {
                    formData.append('logo', this.logo.imageFile)
                }

                axios.post('/register/organization', formData)
                    .then(response => {
                        this.$toasted.show(response.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-green text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                        setTimeout(() => {
                            window.location.href = '/organization/complete-setup'
                        }, 1000)
                    }).catch(error => {
                        console.log(error)
                    this.$toasted.show(error, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-red text-white rounded-full",
                        type: "error",
                        duration: 5000,
                    });
                })
            }
        }
    }
</script>
<style>.cls-1{fill:#fff;}</style>
