<template>
    <div class="flex rounded shadow-lg p-4">
        <div class="flex-1">
            <h3 class="mb-1 text-xl" v-html="product.name"></h3>
            <admin-product-edit :product="product"></admin-product-edit>
        </div>
        <div class="flex-auto text-right">
            <label>
                <input type="checkbox" v-model="available" :value="available" @change="updateAvailability"> Active
            </label>
        </div>
    </div>
</template>
<script>
    import AdminProductEdit from "./AdminProductEdit";
    export default {
        props: ['product'],

        data() {
            return {
                available: this.product.available,

            }
        },

        methods: {
            updateAvailability() {
                let request = {
                   'available': this.available
                }

                axios.put( `/admin/products/availability/${this.product.id}`, request)
                    .then(res => {
                        this.$toasted.show(res.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "top-center text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                    }).catch(err => {

                    })
            }
        }
    }
</script>
