<template>
    <form id="form" class="overflow-hidden md:w-3/4 p-4" enctype="multipart/form-data" @submit.prevent="update">
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="md:flex-auto text-lg text-purple-900 uppercase">Campaign Settings</span>
                <span class="block md:inline-block md:flex-auto md:w-10/12 border-b"></span>
            </legend>
            <div class="mb-6">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="name">
                    Campaign Name
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" id="name" type="text" v-model="name">
            </div>
            <div class="mb-12">
                <label class="block text-gray-700 text-sm font-bold mb-6">
                    Campaign Page Logo
                </label>
                <image-uploader v-model="logo">
                    <template v-slot:preview>
                        <img v-if="logo.imageURL" class="w-1/4 mb-4" :src="logo.imageURL" alt="banner">
                    </template>
                    <template v-slot:activator>
                        <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs">
                            Choose Image
                            <svg class="inline-block float-right h-4 w-4 align-middle ml-2"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                            20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#fff"/></svg>
                        </button>
                    </template>
                </image-uploader>
            </div>
            <div class="mb-12">
                <label class="block text-gray-700 text-sm font-bold mb-6">
                    Campaign Page Background Banner (1920x1080)
                </label>
                <image-uploader v-model="hero">
                    <template v-slot:preview>
                        <img v-if="hero.imageURL" class="w-1/4 mb-4" :src="hero.imageURL" alt="banner">
                    </template>
                    <template v-slot:activator>
                        <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs">
                            Choose Image
                            <svg class="inline-block float-right h-4 w-4 align-middle ml-2"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                            20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#fff"/></svg>
                        </button>
                    </template>
                </image-uploader>
            </div>
        </fieldset>
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="md:flex-auto block lg:inline-block md:w-auto text-lg text-purple-900 uppercase">Campaign Page Message</span>
                <span class="block md:inline-block md:flex-auto md:w-10/12 border-b"></span>
            </legend>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="goal">
                    Content
                </label>
                <trumbowyg class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" v-model="content" :config="config"></trumbowyg>
            </div>
        </fieldset>
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="md:flex-auto block lg:inline-block md:w-auto text-lg text-purple-900 uppercase">Campaign Goal</span>
                <span class="block md:inline-block md:flex-auto md:w-10/12 border-b"></span>
            </legend>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="goal">
                    Goal
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" id="goal" type="text" v-model="goal">
            </div>
        </fieldset>
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="md:flex-auto block lg:inline-block md:w-auto text-lg text-purple-900 uppercase">Campaign Dates</span>
                <span class="block md:inline-block md:flex-auto md:w-10/12 border-b"></span>
            </legend>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="name">
                    Dates
                </label>
                <date-range-picker v-model="date_range" name="date_range" :options="{ timePicker: true }"></date-range-picker>
            </div>
        </fieldset>

        <div class="mt-10">
            <button class="rounded py-2 px-4 text-white bg-purple-900" type="submit">Update Campaign</button>
        </div>

    </form>
</template>

<script>
    import DateRangePicker from "../DateRangePicker";
    import Trumbowyg from 'vue-trumbowyg';
    import 'trumbowyg/dist/ui/trumbowyg.css';
    import ImageUploader from "../ImageUploader";

    export default {
        props: ['campaign'],

        components: {
            DateRangePicker,
            Trumbowyg,
            ImageUploader
        },

        data() {
            return {
                name: this.campaign.name,
                start_date: this.campaign.start_date,
                end_date: this.campaign.end_date,
                logo: {
                    imageFile: null,
                    imageURL: (this.campaign.logo) ? this.campaign.logo.path : null
                },
                hero: {
                    imageFile: null,
                    imageURL: (this.campaign.hero) ? this.campaign.hero.path : null
                },
                goal: this.campaign.page.goal,
                content: this.campaign.page.content,
                config: {}
            }
        },
        computed: {
            date_range: {
                get(){
                    if(this.start_date === null && this.end_date === null) return null;
                    return {
                        begin: this.start_date,
                        end: this.end_date
                    };
                },
                set(v){
                    console.log(v)
                    this.start_date = v.begin;
                    this.end_date = v.end;
                }
            }
        },
        methods: {
            update: function() {
                let request = {
                    organization_id: this.campaign.organization_id,
                    name: this.name,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    goal: this.goal,
                    content: this.content
                }
                var form = document.getElementById('form');
                let formData = new FormData(form);
                formData.append('_method', 'PUT')
                Object.entries(request).map(([prop, val]) => {
                    formData.append(prop, val)
                })
                if (this.logo.imageFile !== null) {
                    formData.append('logo', this.logo.imageFile)
                }

                if (this.hero.imageFile !== null) {
                    formData.append('hero', this.hero.imageFile)
                }

                axios.post('/organization/campaigns/' + this.campaign.id, formData)
                    .then(resp => {
                        this.$toasted.show(resp.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-green text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                        setTimeout(() => {
                            window.scrollTo(0,0)
                        }, 1000)
                    }).catch(err => {
                        this.$toasted.show(err, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-red text-white rounded-full",
                            type: "error",
                            duration: 5000,
                        })
                });
            }
        }
    }
</script>
