<template>
    <form class="" @submit.prevent="donate" >
        <h2 class="text-2xl mb-4">Select a Donation Amount</h2>
        <div class="flex mb-4" v-if="settings.allow_recurring">
            <button type="button" class="rounded bg-gray-100 py-2 px-2 w-auto mr-2 mb-2 flex-1 shadow-md border border-gray-400"
                    :class="{'bg-purple-900 text-white':!recurring}" @click="recurring = false">Once</button>
            <button type="button" class="rounded bg-gray-100 py-2 px-2 w-auto mr-2 mb-2 flex-1 shadow-md border border-gray-400"
                    :class="{'bg-purple-900 text-white':recurring}" @click="recurring = true">Monthly</button>
        </div>

        <div ref="donationButtons" class="flex flex-wrap mt-8 w-auto justify-items-stretch text-center">
            <button v-for="amount in settings.amounts" type="button" class="rounded bg-gray-100
            py-2 px-2 w-auto mr-2 mb-2 flex-1 flex-grow-0 md:flex-grow shadow-md border border-gray-400" :id='"amount-" + amount'
                    @click.prevent="showCustomAmount = false; addDonation(amount)">
                <span class="md:w-3/4">${{ amount }}</span>
            </button>
            <button type="button" ref="customButton" class="rounded bg-gray-100
                py-2 px-2 w-auto mr-2 mb-2 flex-1 flex-grow-0 md:flex-grow shadow-md border border-gray-400"
                :id='"amount-" + customAmount'
                @click.prevent="showCustomInput">Custom</button>
        </div>
        <div class="flex mt-4 mb-2" v-if="showCustomAmount">
            <span class="text-gray-700 shadow appearance-none border-l px-3 py-2 rounded-l leading-tight">$</span>
            <input  type="text" class="shadow appearance-none border-r rounded-r  py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline w-1/2 mr-2"
                   placeholder="40" v-model.number="$v.customAmount.$model" @change="checkAmount">
        </div>
        <span class="block text-red-400 text-sm" v-if="customAmount !== null && $v.customAmount.$error">
            Field is required and must be a number, e.g. 40, decimals are not necessary.</span>

        <span class="block text-red-400 text-sm" v-if="$v.donation.$error">Please select an amount.</span>
        <div class="my-8">
            <label for="public" class="w-full block text-gray-700 text-sm font-bold mb-2">
                <input class="mr-2 leading-tight" type="checkbox" id="public" v-model="public">
                <span class="text-sm">
                    Allow this donation to be listed publicly.
                </span>
            </label>
        </div>
        <div v-if="settings.store_published" class="py-2 mb-4">
            <h2 class="text-2xl mb-4">Support {{campaign.name}} with a Product Purchase</h2>
            <div class="flex flex-wrap">
                <div v-for="product in products.slice(0, 3)" class="w-1/3 text-center">
                    <product-selection-card :product="product" @productAdded="addToCart"></product-selection-card>
                </div>
            </div>
            <transition name="fade">
                <div class="flex flex-wrap" v-if="showMore">
                    <div v-for="product in products.slice(3, 20)" class="w-1/3 text-center">
                        <product-selection-card :product="product" @productAdded="addToCart"></product-selection-card>
                    </div>
                </div>
            </transition>
            <div class="text-center mt-4 mb-2" v-if="products.length > 3">
                <button type="button" class="px-4 text-white uppercase bg-purple-900 rounded text-xs py-2"
                        @click.prevent="showMore = !showMore" v-html="showMoreButton"></button>
            </div>
        </div>

        <div class="border-b mb-8" v-if="cart.length > 0">
            <h2 class="text-2xl mb-4">Your Cart</h2>
            <div v-for="(item, index) in cart">
                <div class="inline-flex w-full py-6 items-center">
                    <div class="w-flex px-2"><img class="w-12" :src="item.image" alt="item.name"></div>
                    <div class="w-1/3 align-middle" v-html="item.name"></div>
                    <div class="w-1/3 align-middle">{{ item.color }} / {{ item.size }}</div>
                    <div class="flex-1 align-middle">{{ item.qty }}</div>
                    <div class="flex-auto text-center align-middle">${{ item.price | toCurrency }} / ea</div>
                    <div class="flex-1 text-right align-middle text-red-500">
                        <svg class="w-4 text-red-500 cursor-pointer" @click="removeFromCart(index)"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="#f56565"
                            d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"/></svg>
                    </div>
                </div>
            </div>
        </div>
        <h2 class="text-2xl mb-4">Your Information</h2>
        <div class="flex justify-between mb-4">
            <div class="w-1/2 mr-4">
                <label for="first_name" class="w-full block text-gray-700 text-sm font-bold mb-2">First Name</label>
                <input type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline" id="first_name" :class="{ 'border-red-400' : $v.first_name.$error }" v-model="$v.first_name.$model">
                <span class="block text-red-400 text-sm" v-if="$v.first_name.$error">Field is required</span>
            </div>
            <div class="w-1/2">
                <label for="last_name" class="w-full block text-gray-700 text-sm font-bold mb-2">Last Name</label>
                <input type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline" id="last_name" :class="{ 'border-red-400' : $v.last_name.$error }" v-model="$v.last_name.$model">
                <span class="block text-red-400 text-sm" v-if="$v.last_name.$error">Field is required</span>
            </div>
        </div>
        <div class="mb-4">
            <label for="email" class="w-full block text-gray-700 text-sm font-bold mb-2">Email</label>
            <input type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline" id="email" :class="{ 'border-red-400' : $v.email.$error }" v-model="$v.email.$model">
            <span class="text-sm text-gray-500 my-2">Your receipt will be emailed here.</span>
            <span class="block text-red-400 text-sm" v-if="$v.email.$error">Field is required</span>
        </div>
        <div v-if="itemsInCart">
            <div class="mb-4">
                <label for="street_address" class="w-full block text-gray-700 text-sm font-bold mb-2">Street Address</label>
                <input type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline" id="street_address" :class="{ 'border-red-400' : $v.street_address.$error }" v-model="$v.street_address.$model">
                <span class="block text-red-400 text-sm" v-if="$v.street_address.$error">Field is required</span>
            </div>
            <div class="flex">
                <div class="mb-4 w-1/2 mr-2">
                    <label for="city" class="w-full block text-gray-700 text-sm font-bold mb-2">City</label>
                    <input type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline" id="city" :class="{ 'border-red-400' : $v.city.$error }" v-model="$v.city.$model">
                    <span class="block text-red-400 text-sm" v-if="$v.city.$error">Field is required</span>
                </div>
                <div class="mb-4 w-1/4 mr-2">
                    <label for="state" class="w-full block text-gray-700 text-sm font-bold mb-2">State</label>
                    <select id="state" class="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight
                        focus:outline-none focus:shadow-outline text-sm" :class="{ 'border-red-400' : $v.state.$error }" v-model="$v.state.$model">
                        <option v-for="state in states">{{ state.code }}</option>
                    </select>
                    <span class="block text-red-400 text-sm" v-if="$v.state.$error">Field is required</span>
                </div>
                <div class="mb-4 w-1/4">
                    <label for="zip" class="w-full block text-gray-700 text-sm font-bold mb-2">Zip</label>
                    <input type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline" id="zip" :class="{ 'border-red-400' : $v.zip.$error }" v-model="$v.zip.$model">
                    <span class="block text-red-400 text-sm" v-if="$v.zip.$error">Field is required</span>
                </div>
            </div>
        </div>
        <div class="mb-4">
            <label for="note" class="w-full block text-gray-700 text-sm font-bold mb-2">Note</label>
            <textarea class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline" id="note" v-model="note" rows="5"></textarea>
        </div>

        <modal ref="processingModal" :closeable="false">
            <template v-slot:body>
                <div class="text-center p-6 overflow-hidden">
                    <img class="block m-auto mb-2" src="/images/spinner.gif">
                    <p class="text-purple-600 text-xl mb-2">
                        Please wait while your transaction is being processed, you will be redirected when it is complete.
                    </p>
                </div>

            </template>
        </modal>

        <credit-card :stripe-key="stripeKey" :processing="processing" :totals="totals"
                     :needsShipping="needsShipping" :hasErrors="hasErrors"
                     @checkErrors="checkErrors" @calculateShipping="calculateShipping"
                     @processTransaction="donate"></credit-card>

    </form>
</template>

<script>
    import CreditCard from "./CreditCard";
    import { required, requiredIf, requiredUnless, integer } from 'vuelidate/lib/validators';
    import ProductSelectionCard from './ProductSelectionCard';
    import Modal from "../ui/Modal";
    import formatHelper from '../../mixins/formatCurrency';

    export default {
        props: [
            'organization',
            'campaign',
            'settings',
            'states',
            'products',
            'stripeKey',
        ],
        mixins: [
            formatHelper
        ],
        components: {
            CreditCard,
            ProductSelectionCard,
            Modal
        },

        data() {
            return {
                campaign_id: (this.campaign.id) ? this.campaign.id : null,
                cart: [],
                cartTotal: 0,
                donation: null,
                first_name: '',
                last_name: '',
                email: '',
                street_address: '',
                city: '',
                state: '',
                zip: '',
                note: '',
                organization_id: (this.campaign.organization_id) ? this.campaign.organization_id : null,
                processing: false,
                public: false,
                recurring: false,
                shipping: null,
                showMore: false,
                fee: 0,
                total: 0,
                showCustomAmount: false,
                customAmount: null,

            }
        },
        validations: {
            donation: {
                required: requiredUnless('itemsInCart')
            },
            first_name: {
                required
            },
            last_name: {
                required
            },
            email: {
                required
            },
            street_address: {
                required: requiredIf('itemsInCart')
            },
            city: {
                required: requiredIf('itemsInCart')
            },
            state: {
                required: requiredIf('itemsInCart')
            },
            zip: {
                required: requiredIf('itemsInCart')
            },
            customAmount: {
                required: requiredIf('showCustomAmount'),
                integer
            }
        },
        computed: {
            hasErrors() {
                return this.$v.$error;
            },
            itemsInCart() {
                return this.cart.length > 0;
            },
            needsShipping() {
                return this.cart.length > 0 && this.shipping === null;
            },
            showMoreButton() {
                let text = ''
                if (this.showMore) {
                    text = 'Show Fewer Products' +
                        '<svg class="inline-block fill-current ml-2" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
                        '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
                        '<g id="minus-outline" fill="#ffffff">' +
                        '<path d="M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 Z M10,18 C14.418278,18 18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 Z M15,9 L15,11 L5,11 L5,9 L15,9 Z" id="Combined-Shape-Copy"></path>' +
                        '</g>' +
                        '</g>' +
                        '</svg>'
                } else {
                    text = 'Show More Products' +
                        '<svg class="inline-block fill-current ml-2" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
                        '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
                        '<g id="add-outline" fill="#ffffff">' +
                        '<path d="M11,9 L11,5 L9,5 L9,9 L5,9 L5,11 L9,11 L9,15 L11,15 L11,11 L15,11 L15,9 L11,9 Z M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 Z M10,18 C14.418278,18 18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 Z" id="Combined-Shape"></path>' +
                        '</g>' +
                        '</g>' +
                        '</svg>'
                }
                return text;
            },
            totals() {
                return {
                    donation: this.donation,
                    recurring: this.recurring,
                    products: this.cartTotal,
                    shipping: this.shipping,
                    fee: this.fee,
                    total: this.total
                }
            }
        },
        methods: {
            addDonation: function (amount) {
                this.donation = null;
                let el = document.getElementById('amount-' + amount);

                this.removeActiveButton()
                if (!this.showCustomAmount && this.customAmount !== null) {
                    this.customAmount = null;
                }

                if (!el.classList.contains('selected')) {
                    console.log('other ' + amount)
                    this.donation = amount;
                    this.setActiveButton(el);
                }

                this.calculate();
            },
            setActiveButton(el) {
                el.classList.add('selected', 'bg-purple-900', 'text-white');
            },
            removeActiveButton() {
                let el = this.$refs.donationButtons.getElementsByClassName('selected');
                if (Array.isArray(el) || el.length === 0) return;
                el[0].classList.remove('selected', 'bg-purple-900', 'text-white');
            },
            showCustomInput() {
                this.showCustomAmount = true;
                if (this.donation !== null) {
                    this.removeActiveButton();
                    this.donation = null;
                    this.calculate();
                }
                this.$refs.customButton.classList.add('selected', 'bg-purple-900', 'text-white');
            },
            checkAmount() {
                if (this.customAmount === null) return;
                this.$v.customAmount.$touch();
                this.addDonation(this.customAmount)
            },
            addToCart(e) {
                this.shipping = null;
                const item = {
                    id: e.id,
                    image: e.image,
                    name: e.name,
                    color: e.color,
                    size: e.size,
                    qty: e.qty,
                    price: e.price
                }
                this.cart.push(item);
                this.calculate();
            },
            removeFromCart(index) {
                this.shipping = null;
                this.$delete(this.cart, index);
                this.calculate();
            },
            calculate() {
               let sum = 0;
               this.cart.forEach((p) => {
                   sum += p.price * p.qty;
               });

               this.cartTotal = sum;
               sum = (this.shipping !== null) ? sum + this.shipping : sum;
               this.addFee(sum + this.donation)

               this.total = this.formatCurrency(sum + this.donation + this.fee);
            },
            addFee(amount) {
                if (amount === 0 || !this.settings.credit_fee) {
                    this.fee = null;
                    return;
                }

                this.fee = Math.round(((amount * .029) + .30) * 100) / 100;
                return this.fee;
            },
            open: function() {
                if (!this.processing) return;
                this.$refs.processingModal.openModal();
            },
            close: function() {
                if (this.processing) return;
                this.$refs.processingModal.closeModal();
            },
            checkErrors() {
                this.$v.$touch();
            },
            calculateShipping: function() {
                this.$v.$touch();
                if (this.$v.$invalid) return;

                let self = this;
                let request = {
                    organization_id: this.organization_id,
                    cart: this.cart,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    street_address: this.street_address,
                    city: this.city,
                    zip: this.zip,
                    state: this.state,
                    cartTotal: this.cartTotal,
                }

                axios.post('/shipping', request)
                    .then(resp => {
                        self.shipping = resp.data.shipping
                        self.calculate();
                    }).catch( err => {
                        console.log(err)
                    })
            },
            donate: function(e) {
                this.processing = true;
                this.open();

                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.processing = false;
                    this.close();
                    return;
                }

                if (this.donation === 0) {
                    this.donation = null;
                }

                let request = {
                    token: e,
                    cart: this.cart,
                    campaign_id: this.campaign_id,
                    organization_id: this.organization_id,
                    public: this.public,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    street_address: this.street_address,
                    city: this.city,
                    zip: this.zip,
                    state: this.state,
                    note: this.note,
                    recurring: this.recurring,
                    donation: this.donation,
                    fee: this.fee,
                    shipping: this.shipping,
                    cartTotal: this.cartTotal,
                    total: this.total,
                }

                axios.post('/donate', request)
                    .then(resp => {
                        if (resp.data.success === 'true') {
                            this.$toasted.show(resp.data.message, {
                                theme: "",
                                position: "bottom-right",
                                className: "bg-green text-white rounded-full",
                                type: "success",
                                duration : 5000,
                            });
                            window.location = `/${this.organization.identifier}/${this.organization.slug}/campaign/${this.campaign.id}/thank-you`
                        } else {
                            this.$toasted.show(resp.data.message, {
                                theme: "",
                                position: "bottom-right",
                                className: "bg-red text-white rounded-full",
                                type: "error",
                                duration: 5000,
                            });
                            this.processing = false;
                        }
                    }).catch(err => {
                        console.log(err)
                        this.$toasted.show(err, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-red text-white rounded-full",
                            type: "error",
                            duration: 5000,
                        });
                        this.processing = false;
                })
            }
        }
    }
</script>
<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
}
</style>
