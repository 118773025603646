<template>
    <div>
        <slot name="preview"></slot>

        <!-- slot for parent component to activate the file changer -->
        <span @click="launchFilePicker()">
            <slot name="activator"></slot>
        </span>
        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input type="file"
               ref="file"
               :name="uploadFieldName"
               @change="onFileChange($event.target.name, $event.target.files)"
               style="display:none">
        <!-- error dialog displays any potential error messages -->
        <div v-if="errorDialog" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
             role="alert">
            <span class="block sm:inline">{{errorText}}</span>
            <span class="absolute top-0 bottom-0 right-0 px-4 py-3" @click="errorDialog = false">
                <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'image-input',
        props: {
            // Use "value" to enable using v-model
            value: Object,
            requiredHeight: {
                type: Number,
                default: 150,
            },
            requiredWidth: {
                type: Number,
                default: 250,
            }
        },
        data() {
            return {
                errorDialog: null,
                errorText: '',
                uploadFieldName: 'file',
                maxSize: 4096,
                height: '',
                width: '',
                reader: null
            }
        },
        methods: {
            launchFilePicker(){
                console.log(this.$refs.file)
                this.$refs.file.click();
            },
            async onFileChange(fieldName, file) {
                const { maxSize } = this
                let imageFile = file[0]

                if (file.length>0) {
                    let size = imageFile.size / maxSize / maxSize


                    try {
                        const response = await this.checkDimensions();

                        if (!imageFile.type.match('image.*')) {
                            // check whether the upload is an image
                            this.errorDialog = true
                            this.errorText = 'Please choose an image file'
                        } else if (this.width < this.requiredWidth || this.height < this.requiredHeight) {
                            this.errorDialog = true
                            this.errorText = `Please upload an image at least ${this.requiredHeight} by ${this.requiredWidth} (height x width).`
                        } else if (size>1) {
                            // check whether the size is greater than the size limit
                            this.errorDialog = true
                            this.errorText = 'Your file is too big! Please select an image under 1MB'
                        } else {
                            // Append file into FormData and turn file into image URL
                            let imageURL = URL.createObjectURL(imageFile)

                            // Emit the FormData and image URL to the parent component
                            this.$emit('input', { imageFile, imageURL })
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
            },
            checkErrors(imageFile, size) {

            },
            checkDimensions() {
                let file = this.$refs.file.files[0]
                let reader = new FileReader();
                let self = this;

                return new Promise((resolve, reject) => {
                    reader.onerror = () => {
                        reader.abort();
                        reject(new DOMException("Problem parsing input file."));
                    };

                    reader.onload = () => {
                        let img = new Image();
                        img.onload = () => {
                            console.log(img.width)
                            self.width = img.width;
                            self.height = img.height;

                            console.log('fires')
                            resolve(img.src);
                        }
                        img.src = reader.result;
                    };

                    reader.readAsDataURL(file);

                })
            }
        }
    }
</script>
