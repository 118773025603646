<template>
    <form @submit.prevent="saveSettings">
        <div class="mb-6 border-b pb-6">
            <div class="relative inline-block w-12 mr-2 select-none align-middle transition duration-200 ease-in">
                <input type="checkbox" name="togglePublished" id="togglePublished" class="toggle-checkbox absolute block w-6 h-6
                    rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                       @change="showUpdate = true" v-model="published"/>
                <label for="togglePublished" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
            </div>
            <label for="togglePublished" class="text-xl text-gray-700 align-middle inline-block" v-html="toggleText"></label>
        </div>
        <div class="mb-8 pt-4">
            <h3 class="text-purple-900 text-xl w-1/3 mb-8">Artwork</h3>
            <ul class="text-sm italic list-inside list-disc mb-4">
                <li>Choose up to 2 additional logo/graphics to have as a visual options on apparel.</li>
                <li>Artwork should be 300dpi/High Res and a minimum of 500px in width.</li>
                <li>.PNG, .PDF or .TIF files with transparent backgrounds are best.</li>
            </ul>
            <div class="flex space-x-2">
                <div v-for="artwork in mediaArray" class="w-1/3 text-center">
                    <div class="flex h-40 p-2 items-center border-4 mb-2 relative text-gray-400 bg-gray-200"
                         :class="[ artwork.default ? 'border-green-500' : 'border-gray-400']">
                        <svg class="absolute top-0 right-0 h-4 w-4 m-1 cursor-pointer" @click="checkArtwork(artwork)" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="trash" fill="#CCC">
                                    <path d="M2,2 L18,2 L18,4 L2,4 L2,2 Z M8,0 L12,0 L14,2 L6,2 L8,0 Z M3,6 L17,6 L16,20 L4,20 L3,6 Z M8,8 L9,8 L9,18 L8,18 L8,8 Z M11,8 L12,8 L12,18 L11,18 L11,8 Z" id="Combined-Shape"></path>
                                </g>
                            </g>
                        </svg>
                        <img class="w-full block" :src="artwork.media.path" alt="print media">
                    </div>
                    <span v-if="artwork.default"
                          class="bg-green-500 p-1 uppercase text-xs text-white w-full block">
                        Default/Selected</span>
                    <span v-else
                          class="p-1 uppercase text-xs w-full block cursor-pointer" @click="setDefault(artwork.id)">
                        Make Default</span>
                </div>
                <div v-if="mediaArray.length < 3" class="flex w-1/3 h-40 p-2 items-center border-4 border-gray-400 text-gray-400 text-center">
                    <a href="#" class="block" @click.prevent="$refs.newProductImageModal.openModal()">
                        <svg class="fill-current h-8 w-8 block m-auto" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="photo" class="fill-current">
                                    <path d="M11,13 L8,10 L2,16 L11,16 L18,16 L13,11 L11,13 Z M0,3.99406028 C0,2.8927712 0.898212381,2 1.99079514,2 L18.0092049,2 C19.1086907,2 20,2.89451376 20,3.99406028 L20,16.0059397 C20,17.1072288 19.1017876,18 18.0092049,18 L1.99079514,18 C0.891309342,18 0,17.1054862 0,16.0059397 L0,3.99406028 Z M15,9 C16.1045695,9 17,8.1045695 17,7 C17,5.8954305 16.1045695,5 15,5 C13.8954305,5 13,5.8954305 13,7 C13,8.1045695 13.8954305,9 15,9 Z" id="Combined-Shape"></path>
                                </g>
                            </g>
                        </svg>
                        <span class="block text-sm px-4 leading-tight">Upload New Artwork</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="pt-4">
            <label for="markup" class="block text-gray-700 text-sm font-bold mb-2">Product Markup</label>
            <div class="inline-flex">
                <span class="text-xl text-gray-700 w-auto py-2" v-html="markupSymbol"></span>
                <input type="text" id="markup" class=" text-gray-700 leading-tight w-auto md:w-1/2
                px-2 py-2 appearance-none focus:outline-none focus:shadow-outline" v-model="markup" @change="showUpdate = true">
            </div>
        </div>
        <div class="mb-4">
            <label class="inline-flex items-center mt-3">
                <input type="radio" class="border-gray-700 checked:bg-gray-900 checked:border-transparent"
                       v-model="markupType" value="percent" :checked="markupType === 'percent'" @change="showUpdate = true">
                <span class="ml-1 text-gray-700 text-xs">% Percent</span>
            </label>
            <label class="inline-flex items-center mt-3 ml-4">
                <input type="radio" class="border-gray-700 checked:bg-gray-900 checked:border-transparent"
                       v-model="markupType" value="dollar" :checked="markupType === 'dollar'" @change="showUpdate = true">
                <span class="ml-1 text-gray-700 text-xs">$ Dollar</span>
            </label>
        </div>

        <modal ref="updateProductImageModal" :closeable="true">
            <template v-slot:header>
                <h1>Update Product Image</h1>
            </template>
            <template v-slot:body>
                <div class="p-4">
                    <p class="block text-gray-700 text-sm mb-4">Please upload the image that will be used on your print products.
                        We recommend an image that is 4500px x 5700px for best quality on all print items.
                    </p>
                    <image-uploader class="w-1/3" v-model="printMedia" @input="close">
                        <div slot="activator">
                            <img v-if="printMedia.imageURL" class="w-full mb-2 bg-gray-200" :src="printMedia.imageURL" alt="print media">
                            <button type="button" class="uppercase rounded bg-gray-200 text-purple-600 my-2 py-1 px-2 text-xs">
                                Choose Image
                                <svg class="inline-block float-right h-3 w-3 align-bottom ml-2"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                                20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#805ad5"/></svg>
                            </button>
                        </div>
                    </image-uploader>
                </div>
            </template>
        </modal>
        <modal ref="newProductImageModal" :closeable="true">
            <template v-slot:header>
                <h1>Add New Artwork</h1>
            </template>
            <template v-slot:body>
                <div class="p-4">
                    <p class="block text-gray-700 text-sm mb-4">Please upload the image that will be used on your print products.
                        We recommend an image that is 4500px x 5700px for best quality on all print items.
                    </p>
                    <image-uploader class="w-1/3" v-model="printMedia" @input="close">
                        <div v-if="printMedia.imageURL" slot="preview">
                            <img class="w-full mb-2 bg-gray-200 p-4" :src="printMedia.imageURL" alt="print media">
                            <button type="button" class="uppercase rounded bg-purple-900 text-white my-2 py-1 px-2 text-xs"
                                @click="upload">
                                Upload Artwork
                            </button>
                        </div>
                        <div v-else slot="activator">
                            <button type="button" class="uppercase rounded bg-gray-200 text-purple-600 my-2 py-1 px-2 text-xs">
                                Choose Image
                                <svg class="inline-block float-right h-3 w-3 align-bottom ml-2"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                                20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#805ad5"/></svg>
                            </button>
                        </div>
                    </image-uploader>
                </div>
            </template>
        </modal>
        <modal ref="artworkModal">
            <template v-slot:header>
                <h3 class="text-2xl">Please Select a New Image</h3>
            </template>
            <template v-slot:body>
                <div class="p-4">
                    <p class="mb-4">This artwork has products associated with it, please select a new image for these product or else
                        they will be deleted and removed from your store.</p>
                    <div class="mb-2 flex">
                        <div v-for="image in mediaReplacementArray" class="w-1/4 flex h-40 p-2 items-center border-4 mr-4 mb-6
                        relative text-gray-400 cursor-pointer" :class="[ mediaReplacement === image.id ? 'border-green-500' : 'border-gray-400']"
                             @click="mediaReplacement = image.id">
                            <img :src="image.media.path" alt="product artwork">
                        </div>

                        <image-uploader class="flex w-1/4 h-40 p-2  border-4 mb-6 border-gray-400"
                                        v-model="printMedia" @input="upload">
                            <div slot="activator">
                                <div class="flex relative content-center items-center cursor-pointer text-center leading-tight">
                                    <div>
                                        <span class="block mb-2 font-bold">Add New Replacement Artwork</span>
                                        <span class="material-icons-outlined block text-green-400 text-4xl">
                                        add_circle
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </image-uploader>
                    </div>
                    <div class="flex">
                        <button class="shadow rounded bg-gray-300 text-sm uppercase mr-2 py-2 px-4" type="button">Cancel</button>
                        <button class="shadow rounded text-white text-sm uppercase py-2 px-4" :class="[mediaReplacement !== null ? 'bg-green-500' : 'bg-red-500']" type="button" @click="deleteArtwork" v-html="deleteButtonText"></button>
                    </div>
                </div>
            </template>
        </modal>
        <button class="rounded bg-purple-900 text-white text-xs py-1 px-2" type="submit" v-if="showUpdate">Update</button>
    </form>
</template>
<script>
    import ImageUploader from "../ImageUploader";
    import Modal from "../ui/Modal";
    export default {
        props: {
            settings: {
                type: Object,
            },
            media: {
                type: Array
            },
        },

        components: {
            Modal,
            ImageUploader
        },
        data() {
            return {
                enabled: this.settings.enabled,
                published: this.settings.published,
                markup: this.settings.markup,
                markupType: this.settings.markupType,
                showUpdate: false,
                mediaArray: (this.media) ? this.media : [],
                deleting: null,
                deleteProcessing: false,
                mediaReplacementArray: [],
                mediaReplacement: null,
                printMedia: {
                    imageFile: null,
                    imageURL:  null,
                },
                artworkModalMessage: ''
            }
        },
        computed: {
            toggleText() {
                return (this.published) ? 'Unpublish Your Store' : 'Publish Your Store';
            },
            markupSymbol() {
                return (this.markupType === 'dollar') ? '$' : '%';
            },
            deleteButtonText() {
                let button = 'Deleting Artwork...';

                if (this.mediaReplacement && !this.deleteProcessing) {
                    button = 'Replace Artwork and Delete'
                }

                if (this.mediaReplacement === null && !this.deleteProcessing) {
                    button = 'Delete Without Replacing';
                }

                return button;
            }
        },
        watch: {
            markup: function() {
                this.showUpdate = true;
            }
        },
        methods: {
            open: function() {
                this.$refs.updateProductImageModal.openModal();
            },
            close: function() {
                this.showUpdate = true
                this.$refs.updateProductImageModal.closeModal();
            },
            upload() {
                let formData = new FormData();
                if (this.printMedia.imageFile !== null) {
                    formData.append('printMediaFile', this.printMedia.imageFile);
                }

                axios.post('/organization/artwork', formData)
                    .then(resp => {
                        this.$refs.newProductImageModal.closeModal();
                        this.mediaArray.push(resp.data.media);
                        this.mediaReplacementArray.push(resp.data.media);
                        this.printMedia = null;
                        this.$toasted.show(resp.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-green text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                        this.printMedia = {
                            imageFile: null,
                            imageURL: null,
                        }
                    }).catch(err => {
                        this.$toasted.show(err, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-red text-white rounded-full",
                            type: "error",
                            duration: 5000,
                        });
                })
            },
            saveSettings() {
                let request = {
                    enabled: true,
                    published: this.published,
                    markup: this.markup,
                    markupType: this.markupType,
                    printMedia: this.printMedia
                }

                axios.post('/organization/store', request)
                    .then(resp => {
                        this.$toasted.show(resp.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-green text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000)
                    }).catch(err => {
                    this.$toasted.show(err, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-red text-white rounded-full",
                        type: "error",
                        duration: 5000,
                    })
                })
            },
            setDefault(id) {
                axios.put('/organization/artwork/' + id)
                    .then(resp => {
                        this.mediaArray.forEach((x) => {
                            if (x.default === true) {
                                x.default = false
                            }

                            if (x.id === id) {
                                x.default = true
                            }
                        });
                        this.$toasted.show(resp.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-yellow-200 text-white rounded-full",
                            duration : 5000,
                        });
                    }).catch(err => {
                    this.$toasted.show(err, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-red text-white rounded-full",
                        type: "error",
                        duration: 5000,
                    });
                })
            },
            checkArtwork(artwork) {
                this.deleting = artwork.id;
                if (artwork.length <= 1) {
                    alert('You must have at least image, please upload a new image before deleting this one.');
                    return;
                }
                if (artwork.has_products) {
                    this.$refs.artworkModal.openModal();
                    this.mediaReplacementArray = this.mediaArray.filter((x) => {
                        return x.id !== artwork.id;
                    });
                    return;
                }

                this.deleteArtwork();
            },
            deleteArtwork() {
                console.log('deleting')
                this.deleteProcessing = true;

                let request = {
                    mediaReplacement: this.mediaReplacement
                };

                axios.post('/organization/artwork/' + this.deleting, request)
                    .then(resp => {
                        this.$refs.newProductImageModal.closeModal();
                        this.mediaArray = this.mediaArray.filter((x) => {
                            return x.id !== this.deleting;
                        });
                        this.$toasted.show(resp.data.message, {
                            theme: "toasted-ef",
                            position: "bottom-right",
                            className: "block bg-red-600 text-white px-4 py-2",
                            duration : 5000,
                        });
                        setTimeout(() => {
                            this.$refs.artworkModal.closeModal();
                            this.deleteProcessing = false;
                            window.location.reload();
                        }, 2000)
                    }).catch(err => {
                    this.$toasted.show(err, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-red-500 text-white rounded-full",
                        type: "error",
                        duration: 5000,
                    });
                })
            }
        }
    }
</script>
