<template>
    <div class="border rounded p-2 my-2" :class="[selected ? 'bg-purple-100' : '']">
        <div class="flex mb-2">
            <div class="flex-auto">{{ variant.title }}</div>
            <div class="flex-auto">{{ variant.options.size }}</div>
            <div class="flex-auto">{{ variant.options.color }}</div>
            <button v-if="!selected" type="button" class="rounded text-white py-1 px-2 text-sm" :class="[ canAdd ? 'bg-purple-900' : 'bg-gray-400 disabled']"
                    @click="addVariant">Add Variant</button>
        </div>

        <div class="flex">
            <div class="flex-1 mb-4 mr-2">
                <label for="color" class="block mb-2">Color </label>
                <input id="color" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="color" placeholder="#CDCDCD">
            </div>
            <div class="flex-1 mb-4 mr-2">
                <label for="price" class="block mb-2">Price</label>
                <input id="price" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="price">
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['variant'],

        data() {
            return {
                name: this.variant.title,
                variantId: this.variant.id,
                price: null,
                color: null,
                description: this.variant.options.color,
                size: this.variant.options.size,
                placeholders: this.variant.placeholders,
                available: true,
                selected: false
            }
        },
        computed: {
            canAdd() {
                return (this.price !== null) && ( this.color !== null);
            }
        },
        methods: {
            addVariant() {
                this.selected = true;
                this.$emit('variant-added', this.$data)
            },

        }
    }
</script>
