/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import moment from 'moment';
import Toasted from 'vue-toasted';
import Vuelidate from 'vuelidate';

Vue.use(Toasted)
Vue.use(Vuelidate)

Vue.component('org-register-form', require('./components/registration/OrgRegistrationForm.vue').default);
Vue.component('stripe-setup-form', require('./components/registration/StripeSetupForm.vue').default);
Vue.component('organization-update', require('./components/organization/OrganizationUpdate.vue').default);
Vue.component('campaign-create', require('./components/campaigns/CampaignCreate.vue').default);
Vue.component('campaign-edit', require('./components/campaigns/CampaignEdit.vue').default);
Vue.component('campaign-list', require('./components/campaigns/CampaignList.vue').default);
Vue.component('campaign-card', require('./components/campaigns/CampaignCard.vue').default);
Vue.component('donors-list', require('./components/donors/DonorsList.vue').default);
Vue.component('new-donor-button', require('./components/buttons/NewDonorButton').default);
Vue.component('donor-form', require('./components/pages/DonorForm').default);
Vue.component('campaign-listing', require('./components/pages/partials/CampaignListing').default);
Vue.component('todays-donations', require('./components/dashboard/TodaysDonations').default);
Vue.component('total-donations', require('./components/dashboard/TotalDonations').default);
Vue.component('progress-bar', require('./components/ui/ProgressBar').default);
Vue.component('store-setup', require('./components/store/StoreSetup').default);
Vue.component('setup-widget', require('./components/store/SetupWidget').default);
Vue.component('base-products-list', require('./components/base-products/BaseProductsList').default);
Vue.component('product-selection', require('./components/products/ProductSelection').default);
Vue.component('order-list', require('./components/orders/OrderList').default);
Vue.component('orders-widget', require('./components/orders/OrdersWidget').default);

Vue.component('admin-product', require('./components/admin/products/AdminProduct').default);
Vue.component('admin-product-edit', require('./components/admin/products/AdminProductEdit').default);
Vue.component('admin-product-create', require('./components/admin/products/AdminProductCreate').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.filter('dateFormat', function(value){
    var d = DateTime.fromISO(value);
    return d.toFormat('DDDD t');
});
Vue.filter('dateFormatF', function(value, format){
    return moment(value).format(format);
});
Vue.filter('humanSize', function(size){
    if(size === 0) return '0B';
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
});
Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});
Vue.filter('upperCase', function (value) {
   return value.charAt(0).toUpperCase() + value.slice(1)
});

const app = new Vue({
    el: '#app',
});
