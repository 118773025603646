
<script>
import { Line } from 'vue-chartjs';

export default {

    extends: Line,

    props: {
         chartdata: {
             type: Object,
             default: null
         }
     },

    data: () => ({
        /*chartdata: {
            labels: ['new', 'old'],
            datasets: [
                {
                    fill: false,
                    backgroundColor: '#f87979',
                    borderColor: '#f87979',
                    data: [0, 80, 100, 350]
                },
                {
                    fill: false,
                    backgroundColor: '#387979',
                    borderColor: '#387979',
                    data: [0, 60, 70, 80]
                }
            ]
        },*/
        options: {
            scales: {
                xAxes: [{
                    ticks: {
                        min: 0,
                        display: true
                    },
                    gridLines: {
                        drawTicks: true,
                        display: true,
                    }
                }],
                yAxes: [{
                    ticks: {
                        min: 0,
                        display: true
                    },
                    gridLines: {
                        drawTicks: true,
                        display: true,
                    }
                }],
            },
            responsive: true,
            maintainAspectRatio: false
        }
    }),

    mounted() {
        this.renderChart(this.chartdata, this.options)
    }
}
</script>
