<template>
    <div class="w-full md:w-1/2 lg:w-1/4 rounded overflow-hidden shadow-lg mb-4 sm:m-4" :class="{'opacity-50': !enabled}">
        <div class="thumbnail">
            <div v-if="!product.images.length > 0 || product.is_updating" class="thumbnail bg-gray-300">
                <img src="/images/products/images/coming_soon.jpg" alt="coming soon">
            </div>
            <a v-else href="#" @click.prevent="open('productImagesModal')">
                <img class="w-full" :src="product.images[0].path" :alt="product.name">
            </a>
        </div>
        <div class="px-6 py-4 flex">
            <div class="w-2/3">
                <div class="mb-2 flex-auto">
                    <h3 class="font-bold text-" v-html="product.name"></h3>
                </div>
                <div class="mb-2">
                    <div class="flex-auto">
                        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input type="checkbox" name="toggle" :id="'toggle-product-'+product.id"
                                   class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4
                                   appearance-none cursor-pointer focus:outline-none" @change="updateAvailability" :checked="enabled"/>
                            <label :for="'toggle-product-'+product.id" class="toggle-label block overflow-hidden
                            h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                        </div>
                        <label class="text-gray-700 text-sm mb-1" v-html="toggleText"></label>
                    </div>
                </div>
            </div>
            <div class="w-1/3 text-right">
                <span class="block text-gray-500 font-bold text-lg mb-1">${{ product.markup_price | toCurrency }}</span>
                <div>
                    <a v-if="enabled" href="#" class="inline-block text-sm text-blue-400" @click.prevent="open('productModal')">Edit</a>
                    <span v-if="enabled" class="inline-block text-gray-400 px-2">|</span>
                    <a href="#" class="inline-block text-sm text-red-400" @click.prevent="deleteProduct">Delete</a>
                </div>
            </div>
        </div>
        <modal ref="productImagesModal">
            <template v-slot:header>
                <h1 class="block mb-2 font-medium text-lg" v-html="product.name"></h1>
            </template>
            <template v-slot:body>
                <div class="px-8">
                    <vue-carousel ref="carousel">
                        <template v-for="(image, index) in product.allImages" v-slot:[index]>
                            <img :src="image.path" alt="product.name" :key="`image-${index}`">
                        </template>
                    </vue-carousel>
                </div>
            </template>
        </modal>
        <modal ref="productModal" @closed="checkSelection">
            <template v-slot:header>
                <h1 class="block mb-2 font-medium text-lg">{{ product.name }}</h1>
            </template>
            <template v-slot:body>
                <div class="p-4">
                    <p class="block mb-4 text-gray-900">Enable color choices and save your product.</p>
                    <form>
                        <div class="w-full py-2 px-1 border-b inline-flex" v-for="(color, index) in product.availableColors">
                            <div class="w-3/5">
                                <span class="h-6 w-6 inline-block align-middle mr-2 border border-gray-400" :style="{'background-color': color.value}"></span>
                                <span class="text-sm align-middle">{{ color.description }}</span>
                            </div>
                            <div class="flex-1 ml-4 text-center">
                                <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                    <input type="checkbox" name="toggle" :id="'toggle-'+index"
                                           class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4
                                           appearance-none cursor-pointer focus:outline-none"
                                           :class="{'cursor-not-allowed':!enabled}"
                                           @change="enableColor" :value="color.description" :disabled="!enabled" :checked="colorEnabled(color.description)"/>
                                    <label :for="'toggle-'+index" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                                </div>
                                <label :id="'switch-'+index" class="text-xs text-gray-700">Enable Color</label>
                            </div>
                        </div>
                    </form>
                </div>
            </template>
            <template slot="footer">
                <div class="py-2 px-4">
                    <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs"
                            :class="{'cursor-not-allowed bg-gray-400':!enabled || saving}"
                            @click="updateProduct" v-html="button" :disabled="!enabled || saving"></button>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
import Modal from '../ui/Modal';
import VueCarousel from '@samwood/vue-carousel';

export default {
    props:[
        'product',
        'markup-type',
        'markup',
        'enable'
    ],
    components: {
        Modal,
        VueCarousel
    },
    data() {
        return {
            enabled: this.enable,
            productPrice: (this.product.variants) ? this.product.variants[0].price : this.product.price,
            enabledColors: this.product.enabledColors,
            disabledColors: [],
            button: 'Update & Save Product',
            saving: false
        }
    },
    computed: {
        toggleText() {
            return (this.enabled) ? 'Disable Product' : 'Enable Product'
        },
    },
    methods: {
        open(name) {
            if (!this.enabled) return false;
            let modal = (name === 'productImagesModal') ? this.$refs.productImagesModal : this.$refs.productModal;
            modal.openModal();
        },
        colorEnabled(description) {
            return this.enabledColors.includes(description);
        },
        enableColor(e) {
            let input = e.target;

            if (input.checked) {
                console.log(input.value)
                this.disabledColors = this.disabledColors.filter(x => {
                    return x !== input.value;
                });
                this.enabledColors.push(input.value)
            } else {
                this.enabledColors = this.enabledColors.filter(x => {
                    return x !== input.value;
                });
                this.disabledColors.push(input.value)
            }
        },
        checkSelection() {
            if (this.enabledColors.length < 1) {
                this.$toasted.show('You must select at least 1 color option to enable this product.', {
                    theme: "",
                    position: "top-center",
                    className: "bg-yellow text-white rounded-full",
                    type: "error",
                    duration : 5000,
                });
                this.enabled = false;
            }
        },
        updateProduct() {
            if (this.enabledColors.length < 1) {
                alert('Please enable at least 1 color.');
                return;
            }

            this.button = 'Saving your product...'
            this.saving = true

            let request = {
                'printify_product_id': this.product.printify_product_id,
                'name': this.product.name,
                'description': this.product.description,
                'price': this.product.price,
                'enabledColors': this.enabledColors,
                'disabledColors': this.disabledColors,
                'available': this.enabled
            }

            axios.post('/organization/products', request)
                .then(resp => {
                    this.$toasted.show('Product Updated', {
                        theme: "",
                        position: "bottom-right",
                        className: "top-center text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                    setTimeout(() => {
                        this.button = 'Update & Save Product';
                        this.saving = false;
                        this.$refs.productModal.closeModal();
                        window.location.reload();
                    }, 2000)
                }).catch(err => {
                this.$toasted.show(err, {
                    theme: "",
                    position: "top-center",
                    className: "bg-red text-white rounded-full",
                    type: "error",
                    duration: 5000,
                })
            })
        },
        updateAvailability() {
            this.enabled = !this.enabled;

            let request = {
                'available': this.enabled
            }

            axios.put(`/organization/products/${this.product.id}`, request)
                .then(resp => {
                    this.$toasted.show(resp.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "top-center text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                }).catch(err => {
                    this.$toasted.show(err, {
                        theme: "",
                        position: "top-center",
                        className: "bg-red text-white rounded-full",
                        type: "error",
                        duration: 5000,
                    })
            })
        },
        deleteProduct() {
            axios.delete(`/organization/products/${this.product.id}`)
                .then(resp => {
                    this.$toasted.show(resp.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "top-center text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                }).catch(err => {
                this.$toasted.show(err, {
                    theme: "",
                    position: "top-center",
                    className: "bg-red text-white rounded-full",
                    type: "error",
                    duration: 5000,
                })
            })
        }
    }
}
</script>

<style>
.thumbnail {
    height: 270px;
    overflow:hidden;
}
</style>
