<template>
    <div class="relative">
        <div class="relative">
            <div class="sm:flex flex-wrap md:flex-no-wrap md:flex-shrink-0 md:overflow-x-auto relative">
                <products-widget class="flex-none w-1/3" v-for="product in products" :product="product" :key="product.id"
                :markup="markup" :markup-type="markupType" :enable="enableSelection(product)"></products-widget>
                <div class="opacity-20 w-2/3 ml-10 absolute top-auto left-auto py-20 z-20 text-center" v-if="showProductButton">
                    <h3 class="text-2xl mb-6">You don't have any products yet, let's add some!</h3>
                    <a href="/organization/products" class="shadow bg-purple-900 text-white uppercase text-sm  rounded py-4 px-20">
                        Add Products
                    </a>
                </div>
            </div>
            <div class="w-full py-10" v-if="!showProductButton && productArray.length <= 12">
                <a href="/organization/products" class="shadow bg-purple-900 text-white uppercase text-sm  rounded py-4 px-20">
                    Add Products</a>
            </div>
        </div>

        <modal ref="storeSetupModal" :closeable="false"
               :class-list="{width: 'w-4/12', top: 'mt-24'}"
               @closed="showProductButton = true">
            <template v-slot:header>
                <h3 class="text-lg bold">Your EventForge Store</h3></template>
            <template v-slot:body>
                <form id="storeform" class="px-4" enctype="multipart/form-data" @submit.prevent="saveSettings">
                    <div v-if="!enabled" class="mb-4 text-center">
                        <div class="relative inline-block w-10 mr-2 align-bottom select-none transition duration-200 ease-in">
                            <input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none" v-model="enabled"/>
                            <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                        </div>
                        <label for="toggle" class="text-xl text-center leading-none text-gray-700">Enable your store.</label>
                    </div>
                    <div v-if="enabled && settings.storeSetup" class="py-2">
                        <h3 class="block text-gray-700 text-sm font-bold mb-2">Set your product markup.</h3>
                        <p class="block text-gray-700 text-sm mb-4">
                            Defaults to percentage markup. You will be able to markup individual products during product selection if you choose to do so.</p>
                        <div class="mb-2 inline-flex">
                            <input type="text" class="shadow border rounded text-gray-700 leading-tight w-2/3 py-2 px-3 appearance-none focus:outline-none focus:shadow-outline" v-model="markup">
                            <span class="block ml-2 text-gray-700 py-1" v-if="markupType === 'percent'">%</span>
                            <span class="block ml-2 text-gray-700 py-1" v-if="markupType === 'dollar'">$</span>
                        </div>
                        <div class="mb-2">
                            <label class="inline-flex items-center mt-3">
                                <input type="radio" class="border-gray-700 checked:bg-gray-900 checked:border-transparent" v-model="markupType" value="percent" :checked="markupType === 'percent'">
                                <span class="ml-1 text-gray-700 text-xs">% Percent</span>
                            </label>
                            <label class="inline-flex items-center mt-3 ml-4">
                                <input type="radio" class="border-gray-700 checked:bg-gray-900 checked:border-transparent" v-model="markupType" value="dollar" :checked="markupType === 'dollar'">
                                <span class="ml-1 text-gray-700 text-xs">$ Dollar</span>
                            </label>
                        </div>
                        <button class="uppercase rounded bg-purple-900 text-white text-xs py-2 px-4 mr-2 inline-block mb-2" v-if="enabled" type="submit">Save</button>
                        <button class="uppercase text-purple-600 text-center text-xs py-2 inline-block" type="button" @click.prevent="cancel">Cancel</button>
                    </div>
                    <div v-else class="pb-2">
                        <p class="mb-2">Your store is in the process of being enabled, our staff will contact you when it is ready for you to complete setup.</p>
                        <p class="mb-4">Thank you for your patience, <span class="bold block">~ EventForge Staff</span></p>
                        <button class="uppercase text-purple-600 text-center text-xxs py-2 inline-block" type="button" @click.prevent="cancel">Return to Dashboard</button>
                    </div>
                </form>
            </template>
        </modal>
    </div>
</template>
<script>
import Modal from "../ui/Modal.vue";
import ProductsWidget from "../products/ProductsWidget";
import ImageUploader from "../ImageUploader";

export default {
    props: {
        products: Array,
        settings: {
            type: Object
        },
        show: {
            type: Boolean
        },
        media: {
            type: Array
        }
    },

    components: {
        ProductsWidget,
        Modal,
        ImageUploader
    },
    data() {
        return {
            enabled: this.settings.enabled,
            markup: this.settings.markup,
            markupType: this.settings.markupType,
            markupSet: this.settings.markupSet,
            productArray: (!this.show) ? this.products : [],
            length: this.products.length,
            showProductButton: this.show,
            printMedia: {
                imageFile: null,
                imageURL: (this.media.length > 0) ? this.media[0].imageURL :  null,
            },
            isLogo: (this.media.length > 0) ? this.media[0].logo :  false,
            useLogo: !!(this.media.length > 0 && this.media[0].logo),
            showMore: false,
        }
    },
    mounted() {
        this.open()
    },
    computed: {
        showMoreButton() {
            return (this.showMore) ? 'Show Less' : 'Show More';
        },
    },
    methods: {
        enableSelection: function(product) {
            if (product.provider_id) return false;
            if('available' in product) {
                return product.available
            }

            return false;
        },
        open: function() {
            if (!this.enabled || !this.settings.storeSetup || !this.markupSet) {
                this.$refs.storeSetupModal.openModal();
            }
        },
        cancel: function() {
            this.$refs.storeSetupModal.closeModal();
            window.location.replace('/organization/dashboard')
        },
        updateMedia: function() {
            if (!this.useLogo) {
                this.printMedia.imageURL = null;
                return;
            }

            this.printMedia.imageURL = this.media[0].imageURL;
        },
        saveSettings() {
            let request = {
                enabled: this.enabled,
                markup: this.markup,
                markupType: this.markupType,
                markupSet: true,
                useLogo: this.useLogo,
                printMedia: this.printMedia
            }

            let formData = new FormData();
            Object.entries(request).map(([prop, val]) => {
                formData.append(prop, val)
            })

            if (this.printMedia.imageFile !== null) {
                formData.append('printMediaFile', this.printMedia.imageFile);
            }

            axios.post('/organization/store', formData)
                .then(resp => {
                    this.$toasted.show(resp.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-green text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                    setTimeout(() => {
                        window.location.reload();
                        this.$refs.storeSetupModal.closeModal();
                    }, 2000)
                }).catch(err => {
                this.$toasted.show(err, {
                    theme: "",
                    position: "bottom-right",
                    className: "bg-red text-white rounded-full",
                    type: "error",
                    duration: 5000,
                })
            })
        }
    }

}
</script>
<style>
.toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #68D391;
}
.fixed-center {
    bottom: 30px;
    left: 50%;
}
</style>
