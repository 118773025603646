<template>
    <div class="md:flex md:ml-4 px-4 mb-2 md:mb-0 py-2 border border-grey-500 uppercase text-xxs leading-5 tracking-wide">
        <span class="inline-block mr-4"><b>Filters:</b></span>
        <div class="relative inline-block mr-4">
            <a href="#" class="cursor-pointer" @click="closeDropdowns('campaignOpen')">
                <span>By Campaign</span>
                <span class="material-icons-outlined text-base align-middle">
                arrow_drop_down
                </span>
            </a>
            <div :class="filters.campaignOpen ? 'block' : 'hidden'" class="bg-white absolute shadow-lg rounded
            p-6 w-auto whitespace-no-wrap">
                <div class="mb-1" v-for="campaign in campaigns">
                    <label><input name="filters" class="mr-2 leading-tight" type="checkbox" @change="setFilter( $event, 'campaign', campaign.id)">
                        <span class="align-top">{{ campaign.name }}</span></label>
                </div>
            </div>
        </div>
        <div class="relative inline-block mr-4">
            <a href="#" class="cursor-pointer" @click.stop="closeDropdowns('dateOpen'); $refs.picker.togglePicker()">
                <span>By Date</span>
                <span class="material-icons-outlined text-base align-middle">
                arrow_drop_down
                </span>
            </a>
            <div :class="filters.dateOpen ? 'block' : 'hidden'" class="bg-white absolute shadow-lg rounded
            p-6 w-auto whitespace-no-wrap">
                <label>Select range: </label>
                <date-range-picker
                    ref="picker"
                    :opens="opens"
                    :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
                    v-model="dateRange",
                    @update="setFilter(null, 'date', dateRange)"
                >
                </date-range-picker>
            </div>
        </div>
        <div class="relative inline-block mr-4">
            <a href="#" class="cursor-pointer" @click.stop="closeDropdowns('amountOpen')">
                <span>By Amount</span>
                <span class="material-icons-outlined text-base align-middle cursor-pointer">
                arrow_drop_down
                </span>
            </a>
            <div :class="filters.amountOpen ? 'block' : 'hidden'" class="bg-white absolute shadow-lg rounded
            p-6 w-auto whitespace-no-wrap">
                <div class="mb-1 w-40 flex">
                    <span class="inline-block border border-r-0 py-2 pl-1">
                        $
                    </span>
                    <input type="text" id="amount" class="appearance-none border border-l-0 w-2/3 py-1 px-2 text-gray-700 text-xxs uppercase tracking-wide leading-5
                focus:outline-none focus:shadow-outline placeholder-current" v-model="amount" placeholder="0">
                    <button class="px-2 py-1 bg-gray-400 ml-2" type="button" @click.prevent="setFilter( $event, 'amount', amount)">Set</button>
                </div>
            </div>
        </div>
        <div class="relative inline-block mr-4">
            <a href="#" class="cursor-pointer" @click.stop="closeDropdowns('recurringOpen')">
                <span>By Recurring</span>
                <span class="material-icons-outlined text-base align-middle" >
                arrow_drop_down
                </span>
            </a>

            <div :class="filters.recurringOpen ? 'block' : 'hidden'" class="bg-white absolute shadow-lg rounded
            p-6 w-auto whitespace-no-wrap">
                <div class="mb-1">
                    <label><input name="filters"  class="mr-2 leading-tight" type="checkbox" @change="setFilter( $event, 'recurring', true)">
                        <span class="align-top">Recurring</span></label>
                </div>
                <div>
                    <label><input name="filters" class="mr-2 leading-tight" type="checkbox" @change="setFilter($event, 'recurring', false)">
                    <span class="align-top">Not Recurring</span></label>
                </div>
            </div>
        </div>
        <a href="#" class="relative inline-block mr-4 cursor-pointer underline text-blue-500" @click="resetFilters">[X] Clear Filters</a>
    </div>
</template>
<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        props: ['campaigns'],
        components: {
            DateRangePicker
        },
        data() {
            return {
                filters: {
                    campaignOpen: false,
                    dateOpen: false,
                    amountOpen: false,
                    recurringOpen: false,
                },
                filtersArray: [],
                opens: 'left',
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                amount: null,
            }
        },
        computed: {
            filterActive: function() {
                var active = false;
                document.getElementsByName('filters').forEach(el => {
                    active = el.checked
                });

                return active;
            }
        },
        methods: {
            closeDropdowns(filter) {
                Object.entries(this.filters).forEach(([k, v]) => {
                    if (k === filter & v) {
                        setTimeout(() => {
                            this.filters[k] = !v
                        }, 550);
                    } else if(k === filter & !v) {
                        this.filters[k] = true
                    } else {
                        this.filters[k] = false;
                    }
                });
            },
            resetCheckboxes(current = null) {
                document.getElementsByName('filters').forEach(el => {
                    if (el !== current) {
                        el.checked = false
                    }
                });
            },
            resetDate() {
                this.dateRange = {
                    startDate: null,
                    endDate: null,
                };
            },
            resetFilters() {
                this.closeDropdowns('none');
                this.resetCheckboxes();
                this.resetDate();
                document.getElementById('amount').value = null;
                this.amount = 0;
                this.filtersArray = [];
                this.$emit('filterChange', {'type': 'clear', 'value': null});
            },
            setFilter(event, type, value) {
                //this.resetCheckboxes(event.target);
                this.closeDropdowns(type + 'Open');

                const resultArr = this.filtersArray.filter((i) => {
                    /*if (type === 'recurring' && i.type === type && i.value === !value) {
                        console.log('value ' + value);
                        return;
                    }*/
                    if (i.type === type && i.value !== value) {
                        return i;
                    }
                    return i.type !== type;
                });
                if (resultArr.length === this.filtersArray.length) resultArr.push({'type': type, 'value': value});
                this.filtersArray = resultArr;

                this.$emit('filterChange', this.filtersArray)
            }
        }
    }
</script>
