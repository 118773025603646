<template>
    <div class="card mx-auto w-full">
        <div class="">
            <h2 class="text-5xl font-light mb-2">Complete Setup</h2>
            <div class="text-right mb-2">
                <span class="inline-block rounded-full mr-2 h-2 w-6 bg-gray-700"></span>
                <span class="inline-block rounded-full mr-2 h-2 w-6" :class="[step >= 2 ? 'bg-gray-700' : 'bg-gray-400']"></span>
                <span class="inline-block rounded-full mr-2 h-2 w-6" :class="[step >= 3 ? 'bg-gray-700' : 'bg-gray-400']"></span>
                <span class="inline-block rounded-full mr-4 h-2 w-6" :class="[step >= 4 ? 'bg-gray-700' : 'bg-gray-400']"></span>
                <span class="inline-block font-bold text-gray-700 text-lg">Step {{ step }} of 4</span>
            </div>
        </div>

        <div class="card-body">
            <form id="registerStripeForm" @submit.prevent="submit" class="bg-purple-900 shadow-md rounded-md px-8 pt-12 pb-8 mb-4">
                <div v-if="step === 4">
                    <p class="text-2xl font-bold text-white uppercase mb-4">Payment Processing</p>

                    <div class="mb-2">
                        <div class="bg-white shadow appearance-none border rounded w-full py-4 px-3
                                        text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                        placeholder-current text-lg md:flex">
                            <div class="md:flex-1 text-center md:text-left py-2">
                                <span>Stripe Integration</span>
                            </div>
                            <div class="text-center md:text-left">
                                <a :href="`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${connect.client_id}&scope=read_write&redirect_uri=${connect.url}&state=${connect.org_id}`"
                                   class="inline-block bg-purple-900 text-base text-white w-auto py-2 px-6 shadow rounded-full">
                                    Connect Stripe Account
                                    <svg class="fill-current inline-block ml-4" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="link" fill="#FFF">
                                                <path d="M13.9001835,11 C13.9656429,10.6768901 14,10.3424658 14,10 C14,7.24419519 11.7636493,5 9.00497092,5 L4.99502908,5 C2.23382212,5 0,7.23857625 0,10 C0,12.7558048 2.23635069,15 4.99502908,15 L6.10084114,15 C5.5100328,14.4211996 5.02141756,13.7385101 4.66454706,12.9813785 C3.16556053,12.8140816 2,11.5402162 2,10 C2,8.34314575 3.3486445,7 5.00032973,7 L8.99967027,7 C10.6567066,7 12,8.34651712 12,10 C12,10.3506354 11.9395995,10.6872211 11.8287087,11 L13.9001835,11 Z M6.09981648,9 C6.03435714,9.32310994 6,9.65753416 6,10 C6,12.7558048 8.23635069,15 10.9950291,15 L15.0049709,15 C17.7661779,15 20,12.7614237 20,10 C20,7.24419519 17.7636493,5 15.0049709,5 L13.9005653,5 C14.4909917,5.5788138 14.9792888,6.26152143 15.3359225,7.01867392 C16.8346829,7.18618441 18,8.45994465 18,10 C18,11.6568542 16.6513555,13 14.9996703,13 L11.0003297,13 C9.34329338,13 8,11.6534829 8,10 C8,9.64936458 8.06040049,9.31277893 8.17129128,9 L6.09981648,9 L6.09981648,9 Z" id="Combined-Shape"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <span class="text-red-500 inline-block mt-2 text-sm" role="alert">
                            <strong></strong>
                        </span>
                    </div>

                    <div class="mb-4">
                        <p class="text-purple-300 text-lg mb-2">Stripe Account Help:</p>
                        <div class="mb-12">
                            <p class="text-white text-sm italic mb-4">
                                Using a Stripe account is one of the safest ways to process payments. Your proceeds will be
                                deposited directly into your bank account that is on file with Stripe. If you do not have a
                                Stripe account you can create one when you click "Connect Stripe Account" above.
                            </p>
                            <svg class="w-1/5" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 34">
                                <title>Powered by Stripe - blurple</title>
                                <path class="cls-1" d="M146,0H3.73A3.73,3.73,0,0,0,0,3.73V30.27A3.73,3.73,0,0,0,3.73,34H146a4,4,0,0,0,4-4V4A4,4,0,0,0,146,0Zm3,30a3,3,0,0,1-3,3H3.73A2.74,2.74,0,0,1,1,30.27V3.73A2.74,2.74,0,0,1,3.73,1H146a3,3,0,0,1,3,3Z"/>
                                <path class="cls-1" d="M17.07,11.24h-4.3V22h1.92V17.84h2.38c2.4,0,3.9-1.16,3.9-3.3S19.47,11.24,17.07,11.24Zm-.1,5H14.69v-3.3H17c1.38,0,2.11.59,2.11,1.65S18.35,16.19,17,16.19Z"/>
                                <path class="cls-1" d="M25.1,14a3.77,3.77,0,0,0-3.8,4.09,3.81,3.81,0,1,0,7.59,0A3.76,3.76,0,0,0,25.1,14Zm0,6.67c-1.22,0-2-1-2-2.58s.76-2.58,2-2.58,2,1,2,2.58S26.31,20.66,25.1,20.66Z"/>
                                <polygon class="cls-1" points="36.78 19.35 35.37 14.13 33.89 14.13 32.49 19.35 31.07 14.13 29.22 14.13 31.59 22.01 33.15 22.01 34.59 16.85 36.03 22.01 37.59 22.01 39.96 14.13 38.18 14.13 36.78 19.35"/>
                                <path class="cls-1" d="M44,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,47.49,20L46,19.38a1.78,1.78,0,0,1-1.83,1.26A2.12,2.12,0,0,1,42,18.47h5.52v-.6C47.54,15.71,46.32,14,44,14Zm-1.93,3.13A1.92,1.92,0,0,1,44,15.5a1.56,1.56,0,0,1,1.69,1.62Z"/>
                                <path class="cls-1" d="M50.69,15.3V14.13h-1.8V22h1.8V17.87a1.89,1.89,0,0,1,2-2,4.68,4.68,0,0,1,.66,0v-1.8c-.14,0-.3,0-.51,0A2.29,2.29,0,0,0,50.69,15.3Z"/><path class="cls-1" d="M57.48,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,60.93,20l-1.54-.59a1.78,1.78,0,0,1-1.83,1.26,2.12,2.12,0,0,1-2.1-2.17H61v-.6C61,15.71,59.76,14,57.48,14Zm-1.93,3.13a1.92,1.92,0,0,1,1.92-1.62,1.56,1.56,0,0,1,1.69,1.62Z"/>
                                <path class="cls-1" d="M67.56,15a2.85,2.85,0,0,0-2.26-1c-2.21,0-3.47,1.85-3.47,4.09s1.26,4.09,3.47,4.09a2.82,2.82,0,0,0,2.26-1V22h1.8V11.24h-1.8Zm0,3.35a2,2,0,0,1-2,2.28c-1.31,0-2-1-2-2.52s.7-2.52,2-2.52c1.11,0,2,.81,2,2.29Z"/>
                                <path class="cls-1" d="M79.31,14A2.88,2.88,0,0,0,77,15V11.24h-1.8V22H77v-.83a2.86,2.86,0,0,0,2.27,1c2.2,0,3.46-1.86,3.46-4.09S81.51,14,79.31,14ZM79,20.6a2,2,0,0,1-2-2.28v-.47c0-1.48.84-2.29,2-2.29,1.3,0,2,1,2,2.52S80.25,20.6,79,20.6Z"/>
                                <path class="cls-1" d="M86.93,19.66,85,14.13H83.1L86,21.72l-.3.74a1,1,0,0,1-1.14.79,4.12,4.12,0,0,1-.6,0v1.51a4.62,4.62,0,0,0,.73.05,2.67,2.67,0,0,0,2.78-2l3.24-8.62H88.82Z"/>
                                <path class="cls-1" d="M125,12.43a3,3,0,0,0-2.13.87l-.14-.69h-2.39V25.53l2.72-.59V21.81a3,3,0,0,0,1.93.7c1.94,0,3.72-1.59,3.72-5.11C128.71,14.18,126.91,12.43,125,12.43Zm-.65,7.63a1.61,1.61,0,0,1-1.28-.52l0-4.11a1.64,1.64,0,0,1,1.3-.55c1,0,1.68,1.13,1.68,2.58S125.36,20.06,124.35,20.06Z"/>
                                <path class="cls-1" d="M133.73,12.43c-2.62,0-4.21,2.26-4.21,5.11,0,3.37,1.88,5.08,4.56,5.08a6.12,6.12,0,0,0,3-.73V19.64a5.79,5.79,0,0,1-2.7.62c-1.08,0-2-.39-2.14-1.7h5.38c0-.15,0-.74,0-1C137.71,14.69,136.35,12.43,133.73,12.43Zm-1.47,4.07c0-1.26.77-1.79,1.45-1.79s1.4.53,1.4,1.79Z"/>
                                <path class="cls-1" d="M113,13.36l-.17-.82h-2.32v9.71h2.68V15.67a1.87,1.87,0,0,1,2.05-.58V12.54A1.8,1.8,0,0,0,113,13.36Z"/><path class="cls-1" d="M99.46,15.46c0-.44.36-.61.93-.61a5.9,5.9,0,0,1,2.7.72V12.94a7,7,0,0,0-2.7-.51c-2.21,0-3.68,1.18-3.68,3.16,0,3.1,4.14,2.6,4.14,3.93,0,.52-.44.69-1,.69a6.78,6.78,0,0,1-3-.9V22a7.38,7.38,0,0,0,3,.64c2.26,0,3.82-1.15,3.82-3.16C103.62,16.12,99.46,16.72,99.46,15.46Z"/>
                                <path class="cls-1" d="M107.28,10.24l-2.65.58v8.93a2.77,2.77,0,0,0,2.82,2.87,4.16,4.16,0,0,0,1.91-.37V20c-.35.15-2.06.66-2.06-1V15h2.06V12.66h-2.06Z"/><polygon class="cls-1" points="116.25 11.7 118.98 11.13 118.98 8.97 116.25 9.54 116.25 11.7"/><rect class="cls-1" x="116.25" y="12.61" width="2.73" height="9.64"/>
                            </svg>
                        </div>

                    </div>
                </div>
                <!-- end step 4 -->

                <div class="form-group row mt-2">
                    <div class="md:flex">
                        <div class="w-full md:align-content-end md:text-right">
                            <button type="button" @click.prevent="skip" class="w-full md:w-auto mr-4 md:right-0 mb-4 md:mb-0 py-2 px-8 rounded-full bg-yellow-400 text-purple-900 text-lg uppercase">
                                Connect Stripe Later
                            </button>
                            <button type="submit" @click.prevent="submit" class="w-full md:w-auto md:right-0 py-2 px-8 rounded-full bg-yellow-400 text-purple-900 text-lg uppercase" :class="[stripe === null ? 'opacity-50 cursor-not-allowed' : '']">
                                Complete SetUp
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import StripeConnect from "../settings/StripeConnect";
export default {
    props: ['connect'],

    components: {
        StripeConnect,
    },

    data() {
        return {
            step: 4,
            error: false,
            stripe: null,
        }
    },
    validations: {
    },
    methods: {
        skip() {
            window.location.href = '/organization/dashboard'
        },
        submit() {
            let request = {

            }

            var form = document.getElementById('registerForm');
            let formData = new FormData(form);
            Object.entries(request).map(([prop, val]) => {
                formData.append(prop, val)
            })

            if (this.banner.imageFile !== null) {
                formData.append('hero', this.banner.imageFile)
            }

            axios.post('/register/organization', formData)
                .then(response => {
                    this.$toasted.show(response.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-green text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                    setTimeout(() => {
                        window.location.href = '/organization/dashboard'
                    }, 1000)
                }).catch(err => {
                this.$toasted.show(err, {
                    theme: "",
                    position: "bottom-right",
                    className: "bg-red text-white rounded-full",
                    type: "error",
                    duration: 5000,
                });
            })
        }
    }
}
</script>
<style>.cls-1{fill:#fff;}</style>
