<template>
    <div>
        <variant-create v-for="variant in variantsArray" :variant="variant"
            :selected="selected(variant.title)"
            :key="variant.id"
            @variant-added="pushVariant"></variant-create>
    </div>
</template>
<script>
    import VariantCreate from "./AdminProductVariantCreate";
    export default {
        props: ['variantsArray', 'selectedVariants'],

        components: {
            VariantCreate
        },
        methods: {
            selected(name) {
                return this.selectedVariants.includes(name);
            },
            pushVariant(e) {
                this.selectedVariants.push(e)

            },
        }
    }
</script>
