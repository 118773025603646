<template>
    <div class="mb-8">
        <button v-if="!showForm" type="button" class="shadow-lg px-4 py-2 text-white bg-purple-900"
                @click="showForm = true">Add Product</button>
        <div :class="[showForm ? 'visible' : 'invisible']">
            <div class="fixed top-0 bottom-0 right-0 h-full
            w-3/4 transform shadow-xl overflow-y-scroll border-l border-gray-200 bg-white p-6">
                <button type="button" class="text-sm" @click="showForm = false">X Close</button>
                <div class="flex py-6">
                    <div class="flex-1">
                        <h1 class="text-xl">Add New Product</h1>
                    </div>
                    <div class="flex-1 text-right">
                        <button type="button" class="shadow-lg px-4 py-2 text-white bg-purple-900"
                                @click="create">Save Product</button>
                    </div>
                </div>
                <form id="productForm" enctype="multipart/form-data" class="flex my-4" v-if="showForm">
                    <div class="mr-8" :class="[ variantsOpen ? 'w-1/3' : 'flex-1']">
                        <div class="mb-4">
                            <label for="name" class="block mb-2">Product Name</label>
                            <input id="name" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="name">
                        </div>
                        <div class="flex">
                            <div class="flex-1 mb-4 mr-2">
                                <label for="blueprint" class="block mb-2">Blueprint Id</label>
                                <input id="blueprint" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="blueprintId">
                            </div>
                            <div class="flex-1 mb-4 mr-2">
                                <label for="provider" class="block mb-2">Provider Id</label>
                                <input id="provider" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="providerId">
                            </div>
                            <div class="flex-1 mb-4">
                                <label for="brand" class="block mb-2">Brand</label>
                                <input id="brand" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="brand">
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="description" class="block mb-2">Description</label>
                            <trumbowyg id="description" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" v-model="description"></trumbowyg>
                        </div>
                        <div class="mb-4">
                            <label for="detail" class="block mb-2">Detail</label>
                            <trumbowyg id="detail" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" v-model="detail"></trumbowyg>
                        </div>
                        <div class="mb-4">
                            <image-uploader v-model="image">
                                <div slot="activator">
                                    <img v-if="image.imageURL" class="w-1/4" :src="image.imageURL" alt="banner">
                                    <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs">
                                        Choose Image
                                        <svg class="inline-block float-right h-4 w-4 align-middle ml-2"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                            20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#fff"/></svg>
                                    </button>

                                </div>
                            </image-uploader>
                        </div>
                    </div>
                    <div class="flex-1">
                        <button v-if="blueprintId" class="shadow bg-gray-200 px-4 py-2 text-sm" type="button"
                                @click="fetchVariants">Fetch Variants</button>
                        <variant-create v-for="variant in variantsArray" :variant="variant"
                                        :key="variant.id"
                                        @variant-added="pushVariant"></variant-create>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import Trumbowyg from 'vue-trumbowyg';
    import AdminVariantList from "./partials/AdminVariantList";
    import VariantCreate from "./partials/AdminProductVariantCreate";
    import ImageUploader from "../../ImageUploader";

    export default {
        components: {
            Trumbowyg,
            VariantCreate,
            ImageUploader
        },
        data() {
            return {
                showForm: false,
                name: '',
                blueprintId: null,
                providerId: 16,
                brand: '',
                description: '',
                detail: '',
                image: {},
                selectedVariants: [],
                variantsArray: null,
                variantsOpen: false,
            }
        },
        methods: {
            pushVariant(e) {
                this.selectedVariants.push(e)
                this.variantsArray.forEach((x) => {
                    if (x.title === e.name) {
                        x.selected = true
                    }
                    return x;
                })
            },
            fetchVariants() {
                let request = {
                    'blueprintId': this.blueprintId,
                    'printProvider': this.providerId
                }
                axios.post('/admin/variants/fetch', request)
                    .then(res => {
                        this.variantsArray = res.data.variants,
                        this.variantsOpen = true,
                        this.$toasted.show(res.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "top-center text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                    }).catch(err => {

                })
            },
            create() {
                let request = {
                    name: this.name,
                    blueprintId: this.blueprintId,
                    providerId: this.providerId,
                    brand: this.brand,
                    description: this.description,
                    detail: this.detail,
                }

                let formData = new FormData();
                Object.entries(request).map(([prop, val]) => {
                    formData.append(prop, val)
                })

                formData.append('variants', JSON.stringify(this.selectedVariants))

                if (this.image.imageFile !== null) {
                    formData.append('image', this.image.imageFile)
                }

                axios.post('/admin/products', formData)
                    .then(res => {
                        this.$toasted.show(res.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "top-center text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                    }).catch(err => {

                })
            }
        }
    }
</script>
