<template>
    <div>
        <div class="w-full shadow appearance-none border rounded py-3 px-2 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline mb-2" ref="card"></div>
        <div id="card-errors" class="block text-red-400 text-sm" role="alert"></div>

        <div class="w-full mt-6">
            <div class="flex" v-for="(total, key) in totals">
                <div v-if="total && key !== 'recurring'" class="flex-1 text-sm uppercase">{{ key }}</div>
                <div v-if="total && key !== 'recurring'" class="flex-1 text-sm text-bold text-right">
                    ${{ total | toCurrency }}
                    <span v-if="key === 'donation'">{{ donationType }}</span>
                </div>
            </div>
        </div>

        <button v-if="needsShipping" class="bg-purple-900 w-full text-white text-uppercase rounded py-3 px-4 mt-6"
                :class="{ 'opacity-50 cursor-not-allowed' : disabled}"
                @click.prevent="calculateShipping" :disabled="disabled" v-html="shipButtonText"></button>
        <button v-else class="bg-green-500 w-full text-white text-uppercase rounded py-3 px-4 mt-6"
                :class="{ 'opacity-50 cursor-not-allowed' : disabled}"
                @click.prevent="purchase" :disabled="disabled" v-html="buttonText"></button>
    </div>
</template>

<script>
export default {
    props: [
        'stripeKey',
        'processing',
        'needsShipping',
        'totals',
        'hasErrors'
    ],

    data() {
        return {
            stripe: null,
            card: undefined,
            calculatingShipping: false,
            hasCardErrors: false,
        }
    },
    computed: {
        formProcessing() {
            return this.processing;
        },
        donationType() {
            return (this.totals.recurring) ? "(Recurring)" : "(One Time)"
        },
        buttonText() {
           return (this.formProcessing) ? "Processing Your Order" : "Confirm &amp; Pay - Total: $" + this.totals.total;
        },
        shipButtonText() {
            return (this.calculatingShipping && !this.hasErrors) ? "Getting Shipping Cost" : "Calculate Shipping";
        },
        disabled() {
            if (this.hasErrors) {
                this.calculatingShipping = false;
                return false;
            }

            if (this.needsShipping) {
                return this.calculatingShipping;
            }

            if (this.refresh) {
                this.refresh = false;
                return this.refresh;
            }

            return this.formProcessing;
        }
    },
    watch: {
        'totals.products': function() {
            this.calculatingShipping = false;
        }
    },
    mounted: function () {
        this.stripe = Stripe(this.stripeKey);

        // Create an instance of Elements
        const elements = this.stripe.elements();


        const style = {
            base: {
                fontSize: '15px',
                lineHeight: '1.5',
                display: 'inline-block',

                ':focus': {
                    color: '#424770',
                },

                '::placeholder': {
                    color: '#9A9A9A',
                },

                ':focus::placeholder': {
                    color: '#DDDDDD',
                },
            },
            invalid: {
                color: '#FA755A',
                ':focus': {
                    color: '#FA755A',
                },
                '::placeholder': {
                    color: '#FFCCA5',
                },
            },
        };

        const elementClasses = {
            focus: 'focus',
            empty: 'empty',
            invalid: 'invalid',
        };

        this.card = elements.create('card', {
            style
        });
        this.card.mount(this.$refs.card);

        this.card.on('change', (e) => {
            let errorElement = document.getElementById('card-errors');
            if (e.error) {
                errorElement.textContent = e.error.message;
            } else {
                errorElement.textContent = '';
            }
        })
    },
    methods: {
        calculateShipping() {
            this.calculatingShipping = true;
            this.$emit('calculateShipping');
        },
        purchase: function () {
            let self = this;
            //this.formProcessing = true;

            this.stripe.createToken(this.card).then(function(result) {
                if (result.error && result.error.message) {
                    self.hasCardErrors = true;
                    self.$forceUpdate();
                    self.$emit('checkErrors');
                    let errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    self.formProcessing = false
                } else {
                    self.$emit('processTransaction', result.token.id);
                }
            });
        },
    }
};
</script>
