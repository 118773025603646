<template>
    <div class="p-2">
        <div class="group relative cursor-pointer" @click="open">
            <img  :src="product.default_image.path">
            <svg class="hidden group-hover:block absolute top-1/2 left-1/2 -m-3 h-8 w-8" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="zoom-in" fill="#ffffff">
                        <path d="M6,6 L4,6 L4,8 L6,8 L6,10 L8,10 L8,8 L10,8 L10,6 L8,6 L8,4 L6,4 L6,6 Z M11.1921711,12.6063847 C10.0235906,13.4815965 8.5723351,14 7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 C10.8659932,0 14,3.13400675 14,7 C14,8.5723351 13.4815965,10.0235906 12.6063847,11.1921711 L14.0162265,12.6020129 C14.6819842,12.4223519 15.4217116,12.5932845 15.9484049,13.1199778 L18.7795171,15.95109 C19.5598243,16.7313971 19.5646685,17.9916807 18.7781746,18.7781746 C17.997126,19.5592232 16.736965,19.5653921 15.95109,18.7795171 L13.1199778,15.9484049 C12.5960188,15.4244459 12.4217025,14.6840739 12.6018353,14.0160489 L11.1921711,12.6063847 Z M7,12 C9.76142375,12 12,9.76142375 12,7 C12,4.23857625 9.76142375,2 7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 Z" id="Combined-Shape"></path>
                    </g>
                </g>
            </svg>
        </div>
        <h3 class="font-bold text-xs leading-tight text-purple-900 uppercase mb-2"  v-html="product.name"></h3>
        <p class="text-xs bg-gray-200 p-1"><span class="text-italic">Sizes:</span> {{ sizeArray }}</p>

        <modal ref="productModal" class="mt-16">
            <template v-slot:header>
                <div class="rounded-full bg-purple-900 text-white m-auto -mt-16 p-6 z-50">
                    <svg class="fill-current w-12 h-12" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="0" fill="none" fill-rule="evenodd">
                            <g id="shopping-cart" fill="#ffffff">
                                <path d="M3,5 L4.33333333,9 L4,9 C2.34314575,9 1,10.3431458 1,12 C1,13.6568542 2.34314575,15 4,15 L17,15 L17,13 L4.00684547,13 C3.45078007,13 3,12.5561352 3,12 C3,11.4477153 3.44748943,11 3.99850233,11 L10.5,11 L17,11 L20,2 L4,2 L4,0.997030139 C4,0.453036308 3.54809015,0 2.9906311,0 L0,0 L0,2 L2,2 L3,5 Z M5,20 C6.1045695,20 7,19.1045695 7,18 C7,16.8954305 6.1045695,16 5,16 C3.8954305,16 3,16.8954305 3,18 C3,19.1045695 3.8954305,20 5,20 Z M15,20 C16.1045695,20 17,19.1045695 17,18 C17,16.8954305 16.1045695,16 15,16 C13.8954305,16 13,16.8954305 13,18 C13,19.1045695 13.8954305,20 15,20 Z" id="Combined-Shape"></path>
                            </g>
                        </g>
                    </svg>
                </div>
            </template>
            <template v-slot:body>
                <div class="md:flex px-6">
                    <div class="w-full md:w-1/2 mr-2 py-6 pr-4 text-center md:border-r relative">
                        <vue-carousel ref="carousel"
                            class="product-carousel"
                            @slide-change="slideChanged"
                            :config="{
                                startingSlide: slideIndex
                            }">
                            <template v-for="(image, index) in productImageArray" v-slot:[index]>
                                <img :src="image.path" :alt="product.name" :key="`image-${index}`">
                                <span class="block mb-6 text-sm text-gray-500 italic">Shown above: {{ image.color }}, {{image.position | upperCase}}</span>
                            </template>
                        </vue-carousel>
                        <div v-if="product.color_selection.length > 1" class="flex md:absolute md:bottom-0 mr-4 mb-6 py-4 bg-gray-200 text-purple-900 italic text-left text-sm">
                            <div class="flex mr-2 w-1/3 border-purple-900 border-r text-center justify-center items-center">
                                <svg class="block align-middle w-6 h-6" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Page-1" stroke="none" stroke-width="0" fill="none" fill-rule="evenodd">
                                        <g id="information-solid" class="fill-current">
                                            <path d="M2.92893219,17.0710678 C6.83417511,20.9763107 13.1658249,20.9763107 17.0710678,17.0710678 C20.9763107,13.1658249
                                            20.9763107,6.83417511 17.0710678,2.92893219 C13.1658249,-0.976310729 6.83417511,-0.976310729
                                            2.92893219,2.92893219 C-0.976310729,6.83417511 -0.976310729,13.1658249 2.92893219,17.0710678
                                            Z M9,11 L9,10.5 L9,9 L11,9 L11,15 L9,15 L9,11 Z M9,5 L11,5 L11,7 L9,7 L9,5 Z" id="Combined-Shape"></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p class="px-2"><span class="font-bold">Note:</span> Not all colors in the color selection drop down are viewable.</p>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 text-left py-6 px-4">
                        <div class="mb-4 pb-4 border-b">
                            <h1 class="block mb-2 font-medium text-2xl tracking-tight" v-html="product.name"></h1>
                            <p class="block text-xl font-light text-purple-900 mb-4">${{ product.markup_price | toCurrency }} / each</p>
                            <div v-html="product.product_data"></div>
                        </div>
                        <div class="mb-6">
                            <label for="color" class="block text-sm">Select Color</label>
                            <select id="color" class="shadow-theme border w-full py-3 px-3 text-gray-700
            focus:outline-none focus:shadow-outline text-base" v-model="selected.color" @change="showColor">
                                <option v-for="(color, key) in product.selections" :value="key">{{ key }}</option>
                            </select>
                        </div>
                        <div class="mb-6">
                            <label for="size" class="block text-sm">Select Size</label>
                            <select id="size" class="shadow-theme border w-full py-3 px-3 text-gray-700
            focus:outline-none focus:shadow-outline text-base" v-model="selected.size">
                                <option v-for="size in availableSizes">{{size}}</option>
                            </select>
                        </div>
                        <div class="border-b mb-4 pb-6 flex align-middle">
                            <div>
                                <label for="qty" class="block text-sm mb-1">Qty</label>
                                <input type="number" class="shadow-theme border w-1/2 py-3 px-3 text-gray-700
            focus:outline-none focus:shadow-outline text-base" id="qty" name="qty" v-model="selected.qty" min="1" size="2" @change="error = null">
                            </div>
                        </div>
                        <div class="flex">
                            <p class="block font-bold text-xl py-5 w-1/2">${{ product.markup_price | toCurrency }} / each</p>
                            <button type="button" class="bg-purple-900 text-white py-3 px-4 my-2 text-base w-1/2" @click="add">Add To Cart</button>
                        </div>
                    </div>
                </div>
                <div v-if="error !== null" class="text-red-500 text-bold">{{ error }}</div>
            </template>
        </modal>

    </div>
</template>
<script>
    import Modal from '../ui/Modal';
    import VueCarousel from "@samwood/vue-carousel";

    export default {
        props: ['product'],
        components: {
            Modal,
            VueCarousel,
        },
        data() {
            return {
                error: null,
                slideIndex: 0,
                defaultImage: '/images/products/images/coming_soon.jpg',
                selected: {
                    id: this.product.id,
                    image: this.product.default_image.path,
                    name: this.product.name,
                    color: this.product.color_selection[0].description,
                    size: this.product.size_selection[0],
                    qty: 1,
                    price: this.product.markup_price
                }
            }
        },
        mounted() {
            this.showColor();
        },
        watch: {
            slideIndex: function (val) {
                let image  = this.productImageArray[val];
                let color = this.product.color_selection.filter((x) => {
                    return image.color.includes(x.description)
                });
                this.selected.color = color[0].description;
                this.productImageArray.forEach((x, i) => {
                    if (x.color.includes(this.selected.color) && x.position === "front") {
                        this.selected.image = (x.path) ? x.path : this.product.default_image.path
                    }
                })
            }
        },
        computed: {
            productImageArray() {

                let arr = this.product.allImages;
                let colorArray = [];
                let colors = [];
                arr.forEach((x) => {
                    colors.push(x.color);
                })

                this.product.color_selection.forEach((x) => {
                    colorArray.push(x.description)
                });

                let diff = colorArray.filter(x => !colors.includes(x))

                diff.forEach((x) => {
                    let image = {
                        color: x,
                        name: 'default',
                        position: 'front',
                        path: window.location.origin + '/images/products/images/coming_soon.jpg'
                    }
                    arr.push(image)
                    return arr;
                })
                return arr;
            },
            sizeArray() {
                return this.product.size_selection.join(' | ')
            },
            availableSizes() {
                //return Object.keys(this.product.selections).find((obj, key) => key === this.selected.color);
                for (const [key, variants] of Object.entries(this.product.selections)) {
                    if (key === this.selected.color) {
                        this.selected.size = variants[0];
                        return variants
                    }
                }
            }
        },
        methods: {
            open: function() {
                this.$refs.productModal.openModal();
            },
            slideChanged(e) {
                this.slideIndex = e;
            },
            colorSelected() {
                this.showSize = true
            },
            showColor() {
                let color = this.productImageArray.length - 1;
                this.productImageArray.forEach((x, i) => {
                    if (x.color.includes(this.selected.color) && x.position === "front") {
                        this.selected.image = (x.path) ? x.path : this.product.default_image.path
                        color = (i)
                    }
                })
                this.slideIndex = color;
            },
            add() {
                if (this.selected.qty < 1) {
                    console.log('less than 1')
                    this.error = "Qty must be 1 or more.";
                    return
                }
                this.$emit('productAdded', this.selected);
                this.$refs.productModal.closeModal();
            }
        }
    }
</script>
<style lang="scss">
    .product-carousel {
        .v-carousel__controls__btn {
            position: static;
            top: auto;
            left: auto;
            transform: none;
        }
        .v-carousel-btn {
            background-color: #cbd5e0;
            font-size: 2rem;
            line-height: 1.25;
            padding: 0 12px;
            width: 40%;

            &:hover, &:focus {
                background-color: #44337a;
            }
        }
        .v-carousel__controls__btn--prev {
            margin-right: 10px;
        }
        .v-carousel__controls__btn--next {
            margin-left: 10px
        }
    }
</style>
