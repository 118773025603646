<template>
    <form id="form" class="overflow-hidden md:w-3/4 p-4" enctype="multipart/form-data" @submit.prevent="create">
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="md:flex-auto block lg:inline-block md:w-auto text-lg text-purple-900 uppercase">Campaign Settings</span>
                <span class="block md:inline-block md:flex-auto md:w-10/12 border-b"></span>
            </legend>
            <div class="mb-10">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="name">
                    Campaign Name
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" :class="{ 'border-red-400' : $v.campaign.name.$error }" id="name" type="text" v-model="$v.campaign.name.$model">
                <span class="block text-red-400 text-sm" v-if="$v.campaign.name.$error">Field is required</span>
            </div>
            <div class="mb-12">
                <label class="block text-gray-700 text-sm font-bold mb-1">
                    Campaign Page Background Banner (1920x1080)
                </label>
                <image-uploader v-model="campaign.hero">
                    <div slot="activator">
                        <img v-if="campaign.hero.imageURL" class="w-1/4" :src="campaign.hero.imageURL" alt="banner">
                        <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs">
                            Choose Image
                            <svg class="inline-block float-right h-4 w-4 align-middle ml-2"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                            20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#fff"/></svg>
                        </button>

                    </div>
                </image-uploader>
            </div>
        </fieldset>
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="md:flex-auto block lg:inline-block md:w-auto text-lg text-purple-900 uppercase">Campaign Page Message</span>
                <span class="flex-auto border-b w-9/12"></span>
            </legend>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="goal">
                    Content
                </label>
                <trumbowyg class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" v-model="campaign.content" :config="config"></trumbowyg>
                <span class="block text-red-400 text-sm" v-if="$v.campaign.content.$error">Field is required</span>
            </div>
        </fieldset>
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="md:flex-auto block lg:inline-block md:w-auto text-lg text-purple-900 uppercase">Campaign Goal</span>
                <span class="flex-auto border-b w-10/12"></span>
            </legend>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="goal">
                    Goal
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" :class="{ 'border-red-400' : $v.campaign.goal.$error }" id="goal" type="text" v-model="$v.campaign.goal.$model">
                <span class="block text-red-400 text-sm" v-if="$v.campaign.goal.$error">Field is required</span>
            </div>
        </fieldset>
        <fieldset>
            <legend class="flex block w-full mb-4 py-4">
                <span class="md:flex-auto block lg:inline-block md:w-auto text-lg text-purple-900 uppercase">Campaign Dates</span>
                <span class="flex-auto border-b w-10/12"></span>
            </legend>
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-1" for="name">
                    Dates
                </label>
                <date-range-picker :error="$v.date_range.$error" v-model="$v.date_range.$model" name="date_range" :options="{ timePicker: true }"></date-range-picker>
                <span class="block text-red-400 text-sm" v-if="$v.date_range.$error">Field is required</span>
            </div>
        </fieldset>

        <div class="mt-10">
            <button type="button" class="rounded py-2 px-4 text-white bg-purple-900" @click="create">Create
            Campaign</button>
        </div>

    </form>
</template>

<script>
    import DateRangePicker from "../DateRangePicker";
    import Trumbowyg from 'vue-trumbowyg';
    import ImageUploader from "../ImageUploader";
    import 'trumbowyg/dist/ui/trumbowyg.css';
    import { required } from 'vuelidate/lib/validators';

    export default {
        props: ['orgId'],

        components: {
            DateRangePicker,
            Trumbowyg,
            ImageUploader
        },

        data() {
            return {
                campaign: {
                    name: '',
                    hero: {
                        imageFile: null,
                        imageURL: null
                    },
                    start_date: null,
                    end_date: null,
                    goal: 0,
                    content: ''
                },
                config: {},
                errors: false
            }
        },
        validations: {
            campaign: {
                name: {
                    required,
                },
                goal: {
                    required,
                },
                content: {
                    required,
                }
            },
            date_range: {
                required
            }
        },
        computed: {
            date_range: {
                get(){
                    if(this.campaign.start_date === null && this.campaign.end_date === null) return null;
                    return {
                        begin: this.campaign.start_date,
                        end: this.campaign.end_date
                    };
                },
                set(v){
                    this.campaign.start_date = v.begin;
                    this.campaign.end_date = v.end;
                }
            }
        },
        methods: {
            create: function() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.errors = true;
                    this.$toasted.show('Your form has errors, please review and submit again.', {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-red text-white rounded-full",
                        type: "error",
                        duration : 5000,
                    });
                } else {
                    this.errors = false;
                    let request = {
                        organization_id: this.orgId,
                        name: this.campaign.name,
                        start_date: this.campaign.start_date,
                        end_date: this.campaign.end_date,
                        goal: this.campaign.goal,
                        content: this.campaign.content
                    }
                    var form = document.getElementById('form');
                    let formData = new FormData(form);
                    Object.entries(request).map(([prop, val]) => {
                        formData.append(prop, val)
                    })

                    if (this.campaign.hero.imageFile !== null) {
                        formData.append('hero', this.campaign.hero.imageFile)
                    }

                    axios.post('/organization/campaigns', formData)
                        .then(resp => {
                            this.$toasted.show(resp.data.message, {
                                theme: "",
                                position: "bottom-right",
                                className: "bg-green text-white rounded-full",
                                type: "success",
                                duration : 5000,
                            });
                            setTimeout(() => {
                                window.location.href = '/organization/campaigns'
                            }, 4000)
                        }).catch(err => {
                        this.$toasted.show(err, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-red text-white rounded-full",
                            type: "error",
                            duration : 5000,
                        });
                    });
                }
            }
        }
    }
</script>
