<template>
    <div class="relative w-auto md:w-1/2 lg:w-1/3 rounded overflow-hidden shadow-lg m-4">
        <div class="thumbnail">
            <img class="w-full" :src="campaign.hero.path" :alt="campaign.title">
        </div>
        <div class="flex px-6 py-4 h-48">
            <div class="mb-2 flex-auto">
                <h3 class="font-bold text-xl">{{ campaign.name }}</h3>
                <div class="text-sm">{{ campaign.page.short_content }}</div>
            </div>
        </div>
        <div class="flex px-6 py-4 text-center">
            <div class="w-1/3 border-r uppercase">
                <span class="block">Raised</span>
                ${{ campaign.total_raised }}
            </div>
            <div class="w-1/3 border-r">
                <span class="block">Goal</span>
                ${{ campaign.page.goal }}
            </div>
            <div class="w-1/3">
                <span class="block">Donors</span>
                {{ campaign.donor_count }}
            </div>
        </div>
        <div class="w-full px-6 py-4 text-center">
            <a :href="`/${organization.identifier}/${organization.slug}/campaign/${campaign.id}`" class="block bg-purple-900 text-white text-center rounded uppercase text-xs py-3 px-2">Donate</a>
        </div>
    </div>
</template>
<script>
export default {
    props:['campaign', 'organization'],

    computed: {}
}
</script>

<style>
.thumbnail {
    height: 225px;
    overflow:hidden;
}
</style>
