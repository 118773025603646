<template>
    <div class="mb-2">
        <a v-if="!showForm" href="#" class="text-purple-700"
                @click.prevent="showForm = true">Edit Product</a>
        <div :class="[showForm ? 'visible' : 'invisible']">
            <div class="fixed top-0 bottom-0 right-0 h-full
            w-3/4 transform shadow-xl overflow-y-scroll border-l border-gray-200 bg-white p-6">
                <button type="button" class="text-sm" @click="showForm = false">X Close</button>
                <form :id="'productEditForm-' + product.id"
                      enctype="multipart/form-data" class="my-4" v-if="showForm" @submit.prevent="update">
                    <div class="flex py-6 mb-4">
                        <div class="flex-1">
                            <h1 class="text-xl">Edit Product</h1>
                        </div>
                        <div class="flex-1 text-right">
                            <button type="submit" class="shadow-lg px-4 py-2 text-white bg-purple-900"
                            >Update Product</button>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="mr-8" :class="[ variantsOpen ? 'w-1/3' : 'flex-1']">
                            <div class="mb-4">
                                <label for="name" class="block mb-2">Product Name</label>
                                <input id="name" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="name">
                            </div>
                            <div class="flex">
                                <div class="flex-1 mb-4 mr-2">
                                    <label for="blueprint" class="block mb-2">Blueprint Id</label>
                                    <input id="blueprint" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="blueprintId">
                                </div>
                                <div class="flex-1 mb-4 mr-2">
                                    <label for="provider" class="block mb-2">Provider Id</label>
                                    <input id="provider" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="providerId">
                                </div>
                                <div class="flex-1 mb-4">
                                    <label for="brand" class="block mb-2">Brand</label>
                                    <input id="brand" type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model="brand">
                                </div>
                            </div>
                            <div class="mb-4">
                                <label for="description" class="block mb-2">Description</label>
                                <trumbowyg id="description" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" v-model="description"></trumbowyg>
                            </div>
                            <div class="mb-4">
                                <label for="detail" class="block mb-2">Detail</label>
                                <trumbowyg id="detail" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                focus:outline-none focus:shadow-outline" v-model="detail"></trumbowyg>
                            </div>
                            <div class="mb-4">
                                <div v-for="img in product.images">
                                    {{ img.path }}
                                </div>
                            </div>
                            <div class="mb-4">
                                <image-uploader v-model="image">
                                    <div slot="activator">
                                        <img v-if="image.imageURL" class="w-1/4" :src="image.imageURL" alt="banner">
                                        <button type="button" class="uppercase rounded bg-purple-900 text-white py-2 px-4 my-2 text-xs">
                                            Choose Image
                                            <svg class="inline-block float-right h-4 w-4 align-middle ml-2"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20
                            20"><path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" fill="#fff"/></svg>
                                        </button>

                                    </div>
                                </image-uploader>
                            </div>
                        </div>
                        <div class="flex-1">
                            <h3 class="mb-4 text-lg">Edit Variants</h3>
                            <div class="flex flex-wrap mb-6">
                                <div class="w-1/2" v-for="variant in variantsArray">
                                    <div class="inline-flex">
                                        <div class="mb-2">
                                            <div class="flex-auto">
                                                <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                    <input type="checkbox" name="toggle" :id="'toggle-variant-'+variant.id"
                                                           class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4
                                   appearance-none cursor-pointer focus:outline-none" @change="updateVariantAvailability(variant)" :checked="variant.available"/>
                                                    <label :for="'toggle-variant-'+variant.id" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div>{{variant.name}} - {{ variant.provider_id }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <label for="provider">Print Provider Id</label>
                                <input type="text" class="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                       focus:outline-none focus:shadow-outline" v-model.number="printProvider">
                            </div>
                                <button v-if="blueprintId" class="flex-auto shadow bg-gray-200 px-2 py-1 text-sm mb-4" type="button"
                                        @click="fetchNewVariants">Fetch New Variants</button>

                            <variant-create v-for="variant in fetchedVariants" :variant="variant"
                                            :key="variant.id"
                                            @variant-added="pushVariant"></variant-create>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Trumbowyg from 'vue-trumbowyg';
import AdminVariantList from "./partials/AdminVariantList";
import VariantCreate from "./partials/AdminProductVariantCreate";
import ImageUploader from "../../ImageUploader";

export default {
    props:['product'],
    components: {
        Trumbowyg,
        VariantCreate,
        ImageUploader
    },
    data() {
        return {
            showForm: false,
            name: this.product.name,
            blueprintId: this.product.blueprint_id,
            providerId: this.product.provider_id,
            brand: this.product.detail.brand,
            description: this.product.detail.description,
            detail: this.product.detail.product_data,
            image: {},
            variantsArray: this.product.all_variants,
            variantsOpen: false,
            editVariants: false,
            fetchedVariants: null,
            newVariants: [],
            printProvider: this.product.provider_id,
        }
    },
    methods: {
        fetchNewVariants() {
            let request = {
                'printProvider': this.printProvider,
                'blueprintId': this.blueprintId
            }
            console.log('fetching')
            var self = this;
            axios.post('/admin/variants/fetch', request)
                .then(res => {
                    self.updateVariants(res.data.variants);
                    this.variantsOpen = true;
                    this.$toasted.show(res.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "top-center text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                }).catch(err => {

            })
        },
        updateVariants(variants) {
            this.fetchedVariants = variants.filter((x) => {
                if (!this.checkValue(x.title)) {
                    return x;
                }
            })
        },
        checkValue(val) {
            return this.variantsArray.some((obj) => obj.name === val && obj.available);
        },
        pushVariant(e) {
            this.variantsArray.push(e)
            this.newVariants.push(e)
        },
        updateVariantAvailability(variant) {
            let request = {
                available: !variant.available
            }
            axios.put(`/admin/variants/${variant.id}`, request)
                .then(res => {
                    console.log('success')
                }).catch(err => {
                this.$toasted.show(err, {
                    theme: "",
                    position: "bottom-right",
                    className: "bg-red text-white rounded-full",
                    type: "error",
                    duration: 5000,
                });
            })
        },
        update() {
            let request = {
                name: this.name,
                blueprintId: this.blueprintId,
                providerId: this.providerId,
                brand: this.brand,
                description: this.description,
                detail: this.detail,
                printProvider: this.printProvider
            }

            //var form = document.getElementById('productEditForm-' + this.product.id);
            let formData = new FormData();
            Object.entries(request).map(([prop, val]) => {
                formData.append(prop, val)
            })

            if (this.newVariants) {
                formData.append('variants', JSON.stringify(this.newVariants));
            }

            if (this.image.imageFile !== null) {
                formData.append('image', this.image.imageFile)
            }
            axios.post(`/admin/products/${this.product.id}`, formData)
                .then(res => {
                    this.$toasted.show(res.data.message, {
                        theme: "",
                        position: "bottom-right",
                        className: "top-center text-white rounded-full",
                        type: "success",
                        duration : 5000,
                    });
                }).catch(err => {
                    this.$toasted.show(err, {
                        theme: "",
                        position: "bottom-right",
                        className: "bg-red text-white rounded-full",
                        type: "error",
                        duration : 5000,
                    });
            })
        }
    }
}
</script>
