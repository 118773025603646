<template>
    <div>
        <h4 class="uppercase text-lg mb-6 px-6">Total Donations By Campaign</h4>
        <div class="relative">
            <div class="flex text-center align-content-center justify-center items-center"
                 :class="{'block absolute top-0 bottom-0 w-full': !active}">
                <h3 v-if="!active" class="text-purple-800 font-bold text-2xl w-2/3 ">
                    Chart inactive due to lack of campaign activity.</h3>
            </div>
            <total-donations-chart-widget :class="{'opacity-25' : !active}"
                          :chartdata="chartdata"></total-donations-chart-widget>
        </div>
    </div>

</template>
<script>
import TotalDonationsChartWidget from "../widgets/TotalDonationsChartWidget";

export default {
    props: {
        chartdata: {
            type: Object,
            default: null
        }
    },
    components: {TotalDonationsChartWidget},
    data() {
        return {
            height: 300
        }
    },
    methods: {
        increase () {
            this.height += 10
        }
    },
    computed: {
        active () {
            return (this.chartdata !== null) ? this.chartdata.active : false
        },
        chartStyles () {
            return {
                height: `${this.height}px`,
                position: 'relative'
            }
        }
    }
}
</script>
