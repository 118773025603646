<template>
    <div>
        <div class="py-4 flex justify-between items-center">
            <div>
                <svg v-if="service.name === 'facebook'" class="w-8 h-8 inline-block mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <path fill="#3b5998"
                          d="M14.5 0h-13C.675 0 0 .675 0 1.5v13c0 .825.675 1.5 1.5 1.5H8V9H6V7h2V6c0-1.653 1.347-3 3-3h2v2h-2c-.55 0-1 .45-1 1v1h3l-.5 2H10v7h4.5c.825 0 1.5-.675 1.5-1.5v-13c0-.825-.675-1.5-1.5-1.5z"/>
                </svg>
                <svg v-if="service.name === 'twitter'" class="w-8 h-8 inline-block mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <path fill="#1da1f2"
                          d="M16 3.538c-.588.263-1.222.438-1.884.516.678-.406 1.197-1.05 1.444-1.816-.634.375-1.338.65-2.084.797-.6-.638-1.453-1.034-2.397-1.034-1.813 0-3.281 1.469-3.281 3.281 0 .256.028.506.084.747-2.728-.138-5.147-1.444-6.766-3.431-.281.484-.444 1.05-.444 1.65 0 1.138.578 2.144 1.459 2.731-.538-.016-1.044-.166-1.488-.409v.041c0 1.591 1.131 2.919 2.634 3.219-.275.075-.566.116-.866.116-.212 0-.416-.022-.619-.059.419 1.303 1.631 2.253 3.066 2.281-1.125.881-2.538 1.406-4.078 1.406-.266 0-.525-.016-.784-.047 1.456.934 3.181 1.475 5.034 1.475 6.037 0 9.341-5.003 9.341-9.341 0-.144-.003-.284-.009-.425.641-.459 1.197-1.038 1.637-1.697z"/>
                </svg>
                <svg v-if="service.name === 'google'" class="w-8 h-8 inline-block mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <path fill="#ea4335"
                          d="M8.159 6.856V9.6h4.537c-.184 1.178-1.372 3.45-4.537 3.45C5.428 13.05 3.2 10.788 3.2 8s2.228-5.05 4.959-5.05c1.553 0 2.594.663 3.188 1.234l2.172-2.091C12.125.787 10.319-.001 8.16-.001c-4.422 0-8 3.578-8 8s3.578 8 8 8c4.616 0 7.681-3.247 7.681-7.816 0-.525-.056-.925-.125-1.325L8.16 6.855z"/>
                </svg>
                <span class="text-gray-700 text-sm font-bold mb-2">{{ service.name }}</span>
                <span class="inline-block px-1 font-normal">|</span>
                <span class="text-sm text-gray-500 font-normal italic">Social login integration for {{ service.name }}</span>
                <div class="mb-4">
                </div>
            </div>

            <div>
                <label class="checkbox-container checkbox-right">
                    <input type="checkbox" v-model="service.enabled"/>
                    <span class="label">Enable</span>
                </label>
            </div>
        </div>

        <div class="lg:flex justify-between w-full lg:w-1/2">
            <div class="mb-4 mr-4 w-full lg:w-1/2">
                <label for="clientId" class="md:w-2/3 block text-gray-700 text-sm font-bold mb-2">Client Id</label>
                <input type="text" class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline w-full" id="clientId" v-model="service.client_id">
            </div>
            <div class="mb-4 w-full lg:w-1/2">
                <label for="clientSecret" class="md:w-2/3 block text-gray-700 text-sm font-bold mb-2">Client Secret</label>
                <input type="text" class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline w-full" id="clientSecret" v-model="service.client_secret">
            </div>
        </div>

        <span class="md:w-2/3 block text-gray-700 text-sm font-bold mb-2">OAuth Callback Links</span>
        <div class="mb-4 w-full lg:w-1/2">
            <input type="text" class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline w-full">
        </div>
        <div class="mb-4 w-full lg:w-1/2">
            <input type="text" class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline w-full">
        </div>
    </div>
</template>
<script>
    export default {
        props: ['service'],

        data() {
            return {
            }
        }
    }
</script>
