<template>
    <div>
        <a href="#" class="w-full md:w-auto button bg-purple-900 p-4 uppercase text-white text-sm md:text-base inline-flex items-center"
           @click.prevent="showForm = !showForm">
            <span class="mr-2">&plus;</span>
            Add Donor Manually
        </a>
        <div class="fixed top-0 bottom-0 right-0
        w-4/5 md:w-3/5 transform shadow-xl overflow-y-scroll border-l border-gray-200 bg-white p-6 z-50 text-left"
             :class="[showForm ? 'visible' : 'invisible']">
            <header class="px-4">
                <button type="button" class="text-sm mb-4 -ml-6" @click="showForm = false">X Close</button>
                <h1 class="block mb-6 font-bold text-xl">Add New Donation</h1>
            </header>
            <form class="p-4 pb-8" @submit.prevent="submit">
                <div class="flex">
                    <div class="mb-6 md:mr-6 w-full md:w-1/2">
                        <label class="block mb-2" for="firstName">First Name
                        <span class="align-top text-red-500">*</span></label>
                        <input type="text" id="firstName" class="appearance-none border border-gray-200 rounded w-full py-2
                        px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        v-model="firstName">
                    </div>
                    <div class="mb-6 w-full md:w-1/2">
                        <label class="block mb-2" for="lastName">Last Name</label>
                        <input type="text" id="lastName" class="appearance-none border border-gray-200 rounded w-full py-2
                        px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        v-model="lastName">
                    </div>
                </div>
                <div class="mb-6">
                    <label class="block mb-2" for="email">Email</label>
                    <input type="email" id="email" class="appearance-none border border-gray-200 rounded w-full py-2
                        px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    v-model="email">
                </div>
                <div class="flex">
                    <div class="mb-4 w-full md:w-1/2 md:mr-6">
                        <label class="block mb-2" for="campaignList">Select a Campaign</label>
                        <select id="campaignList" class="block appearance-none w-full  border border-gray-200 text-gray-700
                     py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        v-model="campaign">
                            <option v-for="campaign in campaigns" :value="campaign.id">{{ campaign.name }}</option>
                        </select>
                    </div>
                    <div class="mb-4 w-full md:w-1/2">
                        <label class="block mb-2" for="amount">Amount</label>
                        <input type="text" id="amount" class="appearance-none  border border-gray-200 rounded w-full py-2
                        px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        v-model="amount">
                    </div>
                </div>
                <div class="md:flex mb-2">
                    <div class="md:w-1/2 mr-6"></div>
                    <div class="md:w-1/2">
                        <label for="public" class="block mb-2">
                            <input type="checkbox" id="public" v-model="public"> Make Public
                        </label>
                    </div>
                </div>
                <div class="mb-6">
                    <label for="message" class="block mb-2">Add a Message</label>
                    <textarea id="message" class="appearance-none  border border-gray-200 rounded w-full py-2
                        px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    v-model="message" rows="4"></textarea>
                </div>
                <div class="fixed w-full left-0 bottom-0 right-0 lg:mt-32">
                    <button type="submit" class="w-full text-center text-lg text-white px-8 py-2 bg-purple-900">Add Donation</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import { required, email, helpers } from 'vuelidate/lib/validators';
    export default {
        props: ['campaigns'],

        data() {
            return {
                firstName: '',
                lastName: '',
                email: '',
                campaign: null,
                amount: 0,
                message: '',
                public: false,
                showForm: false,
            }
        },
        validations: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
            },
            campaign: {
                required
            }
        },
        methods: {
            submit() {
                let request = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email: this.email,
                    campaign_id: this.campaign,
                    donation: this.amount,
                    recurring: false,
                    public: this.public,
                    note: this.message
                };

                axios.post('/organization/donors/add', request)
                    .then(response => {
                        this.$toasted.show(response.data.message, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-green text-white rounded-full",
                            type: "success",
                            duration : 5000,
                        });
                        setTimeout(() => {
                            this.showForm = false
                        }, 1000)
                    }).catch(err => {
                        console.log(err)
                        this.$toasted.show(err, {
                            theme: "",
                            position: "bottom-right",
                            className: "bg-red text-white rounded-full",
                            type: "error",
                            duration: 5000,
                        });
                });
            }
        }
    }
</script>
